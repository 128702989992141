import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, IconButton } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import './GridTable.scss'
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DialogConfirm from './DialogConfirm';

function CustomToolbar({ setFilterButtonRef, isSelectionEmpty, onClickDelete }) {
    return (
        <div className='grid-customToolbar-container'>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton ref={setFilterButtonRef} />
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Données-utilisateurs',
                        delimiter: ';',
                        utf8WithBom: true,
                        columnsToExport: ['fullName', 'genre', 'email', 'telephone', 'ext', 'title', 'department', 'Description'],
                    }}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>

            <div className="btn-supprimer">
                {!isSelectionEmpty && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClickDelete}
                    >
                        Supprimer
                    </Button>
                )}
            </div>
        </div>
    );
}

CustomToolbar.propTypes = {
    setFilterButtonRef: PropTypes.func.isRequired,
    isSelectionEmpty: PropTypes.bool.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

export default function GridTable({ data, setMustReload, allowedManagement }) {

    const [isSelectionEmpty, setIsSelectionEmpty] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(data)
    }, [data]);

    // Fonction de comparaison pour trier des adresses e-mail par leur domaine
    // Cette fonction prend deux adresses e-mail en entrée (emailA et emailB) et les compare
    // en fonction de leur domaine (la partie après le symbole '@').
    // 1. Vérifie si les deux entrées sont des chaînes de caractères. Si ce n'est pas le cas, retourne 0.
    // 2. Sépare les adresses e-mail en deux parties avec 'split("@")' et récupère la partie domaine.
    //    Si l'adresse ne contient pas de '@', elle renverra une chaîne vide par défaut.
    // 3. Utilise 'localeCompare' pour comparer les domaines des deux adresses e-mail, en tenant
    //    compte des différences de majuscules/minuscules et des conventions locales si nécessaire.
    //
    // La fonction est utilisée pour trier des listes d'adresses e-mail en fonction du domaine.
    const domainSortComparator = (emailA, emailB) => {
        if (typeof emailA !== 'string' || typeof emailB !== 'string') {
            return 0; // Retourne 0 si les valeurs ne sont pas des chaînes de caractères
        }
        const domainA = emailA.split("@")[1] ?? '';
        const domainB = emailB.split("@")[1] ?? '';
        return domainA.localeCompare(domainB);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            { field: 'fullName', headerName: 'Nom Complet', width: 200, editable: false, },
            { field: 'email', headerName: 'Courriel', width: 300, editable: false, sortComparator: (a, b) => domainSortComparator(a, b) },
            { field: 'title', headerName: 'Titre', width: 200, editable: false, },
            { field: 'department', headerName: 'Département', width: 150, editable: false, hide: true },
            { field: 'telephone', headerName: 'Téléphone', width: 150, editable: false, },
            { field: 'ext', headerName: 'Poste', width: 80, editable: false, },
            { field: 'Description', headerName: 'Description', width: 200, editable: false, hide: true },
            { field: 'genre', headerName: 'Genre', width: 80, editable: false, hide: true },
        ];

        if (allowedManagement) {
            // .unshift Ajouter la colonne d'édition uniquement pour le rôle 'admin' au debut du tableau
            baseColumns.unshift({
                field: 'edit',
                headerName: 'Éditer',
                width: 80,
                editable: false,
                sortable: false,
                filterable: false,
                align: 'center',
                renderCell: (params) => {
                    const encodedIdUser = encodeURIComponent(params.row.idUser);
                    // Afficher l'idUser dans la console
                    // console.log(params.row.idUser);
                    // console.log(encodedIdUser);

                    // Retourner le JSX
                    return (
                        <Link
                            to={`/dashboard/utilisateurs/edit-user/${encodedIdUser}`}
                            style={{
                                textDecoration: "none",
                                cursor: "pointer",
                            }}
                        >
                            <IconButton className="edit-user">
                                <EditIcon className="edit-icon" />
                            </IconButton>
                        </Link>
                    );
                },
            });
        }

        return baseColumns;
    }, [allowedManagement]);

    const handleSelectionChange = (selection) => {
        setIsSelectionEmpty(selection.length === 0);
        setSelectedRows(selection);
    };

    const onClickDelete = () => {
        setOpen(true)
    };

    return (
        <div className="GridTable">
            {open && (
                <DialogConfirm
                    open={open}
                    setOpen={setOpen}
                    rows={rows}
                    selectedRows={selectedRows}
                    setRows={setRows}
                    setIsSelectionEmpty={setIsSelectionEmpty}
                />
            )}
            <Box className='dataGridBox' sx={{ width: '100%', }} >
                <DataGrid
                    slots={{ toolbar: CustomToolbar, }}
                    slotProps={{
                        panel: {
                            anchorEl: filterButtonRef,
                        },
                        toolbar: {
                            setFilterButtonRef,
                            isSelectionEmpty,
                            onClickDelete,
                        }
                    }}
                    initialState={{ pagination: { paginationModel: { pageSize: 10 } }, }}
                    pageSizeOptions={[10, 50, 100]}

                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    // getRowSpacing={(params) => ({
                    //     top: params.isFirstVisible ? 0 : 5,
                    //     bottom: params.isLastVisible ? 0 : 5,
                    // })}
                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.idUser}

                    disableRowSelectionOnClick
                    // checkboxSelection
                    // onRowSelectionModelChange={handleSelectionChange}
                    checkboxSelection={allowedManagement}
                    onRowSelectionModelChange={allowedManagement ? handleSelectionChange : undefined}

                />
            </Box>
        </div>
    )
}
