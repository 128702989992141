import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './AddUser.scss';
import {
    addNewMunicipalityUser,
} from '../httpQuerys';
import {
    validatePassword,
    validateField,
    validateEmail,
    validateUserName
} from '../FunctionsUtils';
import {
    Paper,
    Button,
} from '@mui/material';

import DialogConfirm from './DialogConfirm';
import UserInfoForm from './User-Info-Form/UserInfoForm';
import UserProfilesServicesForm from './User-Profiles-Services-Form/UserProfilesServicesForm';

import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

export default function AddUser() {

    const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

    const addUserFormRef = useRef(null);
    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    const { cityCode } = currentCity
    const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
    const { idGroup } = currentUserInfo;

    const [userToAdd, setUserToAdd] = useState(
        {
            currentCity: currentCity,
            idGroup: idGroup,
            prenom: '',
            nom: '',
            fullName: '',
            userName: '',
            password: '',
            email: '',
            telephone: '',
            ext: '',
            genre: null,
            title: null,
            idtitle: null,
            Description: '',
            iddepartment: null,
            department: null,
            roleName: '',
            idRole: null,
            profile: null,
            servicesProfile: [],
            defaultServiceProfile: null,
            expiration: toDay,
            contact: false,
        }
    );
    const [prenomError, setPrenomError] = useState('');
    const [nomError, setNomError] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [telephoneError, setTelephoneError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');

    const [profileError, setProfileError] = useState('');
    const [profileServicesError, setProfileServicesError] = useState('');
    const [defaultServiceError, setDefaultServiceError] = useState('');

    // const [isAccordionValidated, setIsAccordionValidated] = useState(false);
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);

    // const attributDefaultService = (userToAdd, setUserToAdd) => {
    //     const updatedServicesProfile = userToAdd.servicesProfile.map((item) => ({
    //         ...item,
    //         defaultService: item.aliasName === userToAdd.defaultServiceProfile.aliasName ? 1 : 0
    //     }));
    //     const updatedUser = {
    //         ...userToAdd,
    //         servicesProfile: updatedServicesProfile
    //     };
    //     setUserToAdd(updatedUser);
    //     return updatedUser;
    // };
    const attributDefaultService = (userToAdd) => {
        const updatedServicesProfile = userToAdd.servicesProfile.map((item) => ({
            ...item,
            defaultService: item.aliasName === userToAdd.defaultServiceProfile.aliasName ? 1 : 0
        }));
        ;
        return updatedServicesProfile;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        validateField(userToAdd.prenom, 'Prénom', setPrenomError);
        validateField(userToAdd.nom, 'Nom', setNomError);
        await validateUserName(userToAdd.userName, "Identifiant de l'utilisateur", setUserNameError);
        validateEmail(userToAdd.email, setEmailError)
        validatePassword(userToAdd.password, setPasswordError, setPasswordStrength)

        if (userToAdd.profile === null) setProfileError('Un profil est obligatoire.')
        if (userToAdd.servicesProfile.length === 0) setProfileServicesError('Au minimum un service est obligatoire.')
        if (userToAdd.defaultServiceProfile === null) setDefaultServiceError('Le champ service par défaut est obligatoire.')

        // console.log('=========>>>> userToAdd : ', userToAdd)
        // console.log('userToAdd.defaultServiceProfile !== null : ', userToAdd.defaultServiceProfile !== null)
        // const updatedUser = userToAdd.defaultServiceProfile !== null ? attributDefaultService(userToAdd, setUserToAdd) : userToAdd;

        const updatedUser = {
            ...userToAdd,
            servicesProfile: userToAdd.defaultServiceProfile !== null ? attributDefaultService(userToAdd, setUserToAdd) : userToAdd.defaultServiceProfile,
            // Si la date d'expiration n'est pas valide, assigner null
            expiration: dayjs(userToAdd.expiration).isValid() ? userToAdd.expiration : null
        }

        // console.log('=========>>>> updated User : ', updatedUser)

        let validateForm =
            (updatedUser.nom !== '' && !nomError) &&
            (updatedUser.prenom !== '' && !prenomError) &&
            (updatedUser.userName !== '' && !userNameError) &&
            (updatedUser.password !== '') &&
            (updatedUser.email !== '' && !emailError) &&
            // (updatedUser.password !== '' && !passwordError) &&
            // (updatedUser.telephone !== '' && !telephoneError) &&
            (updatedUser.profile !== null) &&
            (updatedUser.servicesProfile.length > 0) &&
            (updatedUser.defaultServiceProfile !== null)

        // console.log({ validateForm })
        // console.log('=========>>>> updatedUser : ', updatedUser)

        if (validateForm) {
            const result = await addNewMunicipalityUser(updatedUser);
            if (result) {
                // console.log('Soumission du Formulaire', { updatedUser });
                console.log('Soumission du Formulaire');
                setIsFormValidated(true);
                // permet d'afficher la boite de dialogue à la soumission du formulaire
                // setOpen(true);
            }
        }
        else {
            console.log('Un des champs est manquant ou mal saisi');
            setIsFormValidated(false);
        }

        // permet d'afficher la boite de dialogue à la soumission du formulaire
        setOpen(true);
    };

    return (
        <div className='muAddUser'>

            {open && (
                <DialogConfirm
                    userToAdd={userToAdd}
                    open={open}
                    setOpen={setOpen}
                    isFormValidated={isFormValidated}
                />
            )}

            <Paper elevation={5} className='muAddUser-paper' sx={{ height: 600, overflowY: 'auto' }}>
                <form ref={addUserFormRef} className='muAddUserForm' onSubmit={handleSubmit}>

                    <UserInfoForm
                        userToAdd={userToAdd}
                        setUserToAdd={setUserToAdd}
                        prenomError={prenomError}
                        setPrenomError={setPrenomError}
                        nomError={nomError}
                        setNomError={setNomError}
                        userNameError={userNameError}
                        setUserNameError={setUserNameError}
                        passwordError={passwordError}
                        setPasswordError={setPasswordError}
                        emailError={emailError}
                        setEmailError={setEmailError}
                        telephoneError={telephoneError}
                        setTelephoneError={setTelephoneError}
                        passwordStrength={passwordStrength}
                        setPasswordStrength={setPasswordStrength}
                    />
                    <hr />
                    <UserProfilesServicesForm
                        userToAdd={userToAdd}
                        setUserToAdd={setUserToAdd}
                        profileError={profileError}
                        setProfileError={setProfileError}
                        profileServicesError={profileServicesError}
                        setProfileServicesError={setProfileServicesError}
                        defaultServiceError={defaultServiceError}
                        setDefaultServiceError={setDefaultServiceError}
                    // isAccordionValidated={isAccordionValidated}
                    />
                    <hr />

                    <div className='footerAddUser'>
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-utilisateurs`} style={{ textDecoration: "none" }}>
                            <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                                Annuler
                            </Button>
                        </Link>

                        <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                            Enregistrer
                        </Button>

                    </div>

                </form>
            </Paper>

        </div>
    )
}
