import React, { useEffect, useState } from 'react';
import './UserProfilesServicesForm.scss';
import {
  getProfileList,
  getServicesProfile,
} from '../../httpQuerys';
import TextField from '@mui/material/TextField';
import { Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function UserProfilesServicesForm(props) {

  const {
    userToEdit,
    setUserToEdit,
    profileError,
    setProfileError,
    profileServicesError,
    setProfileServicesError,
    defaultServiceError,
    setDefaultServiceError,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const { cityCode } = currentCity

  const [profilesList, setProfilesList] = useState([]);
  const [profile, setProfile] = useState(null);

  const [profileServicesList, setProfileServicesList] = useState([]);
  const [servicesProfile, setServicesProfile] = useState([]);

  const [defaultServiceProfileList, setDefaultServiceProfileList] = useState([]);
  const [defaultServiceProfile, setDefaultServiceProfile] = useState({});

  // ---------------------------------------------------------------
  const [expiration, setExpiration] = useState(dayjs());
  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");
  // ---------------------------------------------------------------

  //  valeurs a récupérer de la BD la liste des profils
  useEffect(() => {
    getProfileList(userToEdit.idGroup).then(data => {
      setProfilesList(data)
      setProfile(data.find(item => item.nameProfile === userToEdit.profile))
    });

    setServicesProfile(userToEdit.servicesProfile)
    getServicesProfile(cityCode, userToEdit.idGroup, userToEdit.profile).then(data => {
      setProfileServicesList(data)
    });

    setDefaultServiceProfileList(userToEdit.servicesProfile)
    setDefaultServiceProfile(userToEdit.defaultServiceProfile)
    // setDefaultServiceProfile(userToEdit.servicesProfile.find(service => service.defaultService === true))
    setExpiration(dayjs(userToEdit.expiration))
  }, [cityCode, userToEdit]);

  // données des Options de la liste déroulante pour les profils
  const profilesDefaultProps = {
    options: profilesList,
    getOptionLabel: (option) => option.nameProfile
  };

  // données des Options de la liste déroulante pour les servicesProfile
  const servicesProfileDefaultProps = {
    options: profileServicesList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  // données des Options de la liste déroulante des servicesProfile accordés à l'utilisateur
  const defaultServiceProfileDefaultProps = {
    options: defaultServiceProfileList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  const handleDateExpirationChange = (newValue) => {
    const formattedDateTime = newValue.locale('fr').format('YYYY-MM-DD HH:mm:ss');
    setExpiration(newValue);
    setUserToEdit(prevUser => ({ ...prevUser, expiration: formattedDateTime }))
  }

  return (
    <div className='UserProfilesServicesForm'>
      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem checkboxItem ' xs={12} md={12} lg={12} >
          <FormLabel className='checkboxItem-title'>PROFIL ET THÉMATIQUES</FormLabel>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1}>
              <Autocomplete
                {...profilesDefaultProps}
                id="profiles"
                name="profiles"

                isOptionEqualToValue={(option, value) => option.nameProfile === value.nameProfile}

                value={profile}

                onChange={(e, newValue) => {
                  if (newValue) {
                    // console.log(newValue);
                    setProfile(newValue);
                    getServicesProfile(cityCode, userToEdit.idGroup, newValue.nameProfile)
                      .then(data => {
                        setProfileServicesList(data);
                        setServicesProfile([])
                        setProfileError('');
                        setDefaultServiceProfileList([])
                        setDefaultServiceProfile({})
                        setUserToEdit((prevState) => ({
                          ...prevState,
                          profile: newValue.nameProfile,
                          servicesProfile: [],
                          defaultServiceProfile: null
                        }));
                      });
                  }
                }}

                // clearOnEscape
                disableClearable
                renderInput={(params) => <TextField {...params} label="Profils" variant="standard" name="profiles" />}
              />
            </Stack>
            {profileError && <Typography variant="caption" color="error">{profileError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem checkboxItem ' xs={12} md={6} lg={6} ></Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...servicesProfileDefaultProps}
                multiple
                id="multipleServicesProfile"
                name="multipleServicesProfile"
                disableCloseOnSelect
                disabled={!userToEdit.profile}

                isOptionEqualToValue={(option, value) => option.aliasName === value.aliasName}

                value={servicesProfile}

                onChange={(e, newValue) => {
                  // console.log(newValue);
                  if (newValue) {

                    const updatedServicesProfile = newValue.map(item => ({
                      idService: item.idService,
                      aliasName: item.aliasName,
                      defaultService: 0
                    }));

                    setServicesProfile(updatedServicesProfile)
                    setProfileServicesError('')
                    setDefaultServiceProfileList([])
                    setDefaultServiceProfile(newValue?.length === 1 ? newValue[0] : null)
                    setDefaultServiceError('')

                    setUserToEdit((prevState) => ({
                      ...prevState,
                      servicesProfile: updatedServicesProfile,
                      defaultServiceProfile: newValue?.length === 1 ? newValue[0] : null

                    }));
                  }
                }}

                // disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.aliasName}
                    </li>
                  )
                }}

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Thématiques du profil" variant="standard" name="multipleServicesProfile" />}
              />
            </Stack>
            {profileServicesError && <Typography variant="caption" color="error">{profileServicesError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...defaultServiceProfileDefaultProps}
                id="defaultServiceProfile"
                name="defaultServiceProfile"
                disabled={userToEdit.servicesProfile.length === 0}

                isOptionEqualToValue={(option, value) => option.idService === value.idService }

                value={defaultServiceProfile}

                onChange={(e, newValue) => {
                  // console.log(newValue);
                  if (newValue) {
                    setDefaultServiceProfile({ ...newValue, defaultService: 1 });
                    setDefaultServiceError('')

                    setUserToEdit((prevState) => ({
                      ...prevState,
                      defaultServiceProfile: { ...newValue, defaultService: 1 }
                    }));

                  }
                }}

                // clearOnEscape
                disableClearable
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Thématique par defaut du profil"
                      variant="standard"
                      name="defaultServiceProfile"
                    />
                  )
                }}
              />
            </Stack>
            {defaultServiceError && <Typography variant="caption" color="error">{defaultServiceError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
            <FormControlLabel
              className='contact-formControl'
              control={
                <Checkbox
                  className='contact-checkbox'
                  id="contact"
                  name="contact"
                  checked={userToEdit.contact}
                  onChange={(e) => setUserToEdit({ ...userToEdit, [e.target.name]: e.target.checked })}

                  color="primary"
                  inputProps={{ 'aria-label': 'contact checkbox' }}
                />
              }
              label={
                <div className='contact-label'>
                  <PermContactCalendarIcon
                    sx={{
                      color: userToEdit.contact ? 'green' : 'action.active',
                      marginRight: '0.5rem'
                    }}
                  />
                  Contact
                </div>
              }
              labelPlacement="start"
            />
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            // locale={frFR} // Utilisez la locale française canadienne pour @mui/x-date-pickers
            locale="fr-FR"
          >
            <DatePicker
              label="Date d'expiration"
              format="YYYY/MM/DD"
              openTo="day"
              views={["year", "month", "day"]}
              slotProps={{
                textField: {
                  variant: "filled",
                  size: "small",
                  // helperText: "Saisir une date AAAA/MM/JJ"
                }
              }}
              value={dayjs(expiration) || null}
              onChange={(newValue) => {
                // console.log(newValue)
                handleDateExpirationChange(newValue)
              }}
            />
          </LocalizationProvider>
        </Grid>

      </Grid>
    </div>
  )
}
