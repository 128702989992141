import './App.scss'
// import React, { useEffect, useState } from 'react';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'
import Dashboard from './pages/dashboard/Dashboard'
import Signin from './pages/signin/Signin'
import ForgotPW from './pages/forgot-pw/ForgotPW'
import ErrorPage from './pages/errorPage/ErrorPage'
import MuListe from './pages/municipalites/Mu-Liste/MuListe'
import Utilisateurs from './pages/utilisateurs/Utilisateurs'
import Messages from './pages/messages/Messages'
import Profile from './pages/profile/Profile'
import MuMessages from './pages/municipalites/Mu-Messages/MuMessages'
import MuUtilisateurs from './pages/municipalites/Mu-Utilisateurs/MuUtilisateurs'
import MuStatistiques from './pages/municipalites/Mu-Statistiques/MuStatistiques'
import MuCartographie from './pages/municipalites/Mu-Cartographie/MuCartographie'
import EditUser from './pages/municipalites/Mu-Utilisateurs/Mu-Edit-User/EditUser'
import MuEditMessage from './pages/municipalites/Mu-Messages/Mu-Edit-Message/MuEditMessage'
import EditOneUserOfAll from './pages/utilisateurs/Edit-OneUserOfAll/EditOneUserOfAll'
import ResetPassword from './pages/reset-password/ResetPassword'
import MuConfiguration from './pages/municipalites/Mu-Configuration/MuConfiguration'
import EditOneMessageOfAll from './pages/messages/Edit-OneMessageOfAll/EditOneMessageOfAll';

import MuMiseEnLigne from './pages/municipalites/Mu-MiseEnLigne/MuMiseEnLigne'

function App() {

  const { currentUser } = useAuth();
  // const [roleName, setRoleName] = useState(null);
  // useEffect(() => {
  //   // Récupérer le rôle depuis le localStorage après le login
  //   const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  //   if (currentUserInfo) {
  //     setRoleName(currentUserInfo.roleName);
  //   }
  // }, [currentUser]); // Exécuter l'effet lorsque currentUser change
  // Tableau des rôles autorisés pour la gestion des utilisateurs
  // ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  // const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_GESTION_UTILISATEURS', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];

  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/forgot-pw" element={<ForgotPW />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        <Route path="dashboard/*" element={currentUser ? (<Dashboard />) : (<Navigate to="/login" replace />)} >
          <Route path="municipalites" element={<MuListe />} />
          <Route path="municipalites/:cityCode" element={<MuStatistiques />} />
          <Route path="municipalites/:cityCode/mu-statistiques" element={<MuStatistiques />} />

          {/* <Route path="municipalites/:cityCode/mu-utilisateurs" element={<MuUtilisateurs />} />
          <Route path="municipalites/:cityCode/:mrcCode/mu-utilisateurs/edit-user/:idUser" element={<EditUser />} />
          <Route path="municipalites/:cityCode/:mrcCode/mu-utilisateurs/add-new-user-to-municipality" element={<AddUser />} />
          <Route path="municipalites/:cityCode/:mrcCode/mu-utilisateurs/add-municipality-to-user" element={<AddMuToUser />} /> */}

          <Route path="municipalites/:cityCode/mu-utilisateurs/:page" element={<MuUtilisateurs />} />
          <Route path="municipalites/:cityCode/mu-utilisateurs" element={<Navigate to={`${window.location.pathname}/mu-users-list`} replace />} />
          {/* <Route path="municipalites/:cityCode/mu-utilisateurs/add-new-user-to-municipality" element={<AddUser />} />
          <Route path="municipalites/:cityCode/mu-utilisateurs/add-municipality-to-user" element={<AddMuToUser />} /> */}
          <Route path="municipalites/:cityCode/:idGroup/mu-utilisateurs/edit-user/:idUser" element={<EditUser />} />


          <Route path="municipalites/:cityCode/mu-cartographie" element={<MuCartographie />} />

          <Route path="municipalites/:cityCode/mu-mise-en-ligne/:page" element={<MuMiseEnLigne />} />
          <Route path="municipalites/:cityCode/mu-mise-en-ligne" element={<Navigate to={`${window.location.pathname}/list`} replace />} />


          <Route path="municipalites/:cityCode/mu-configuration/:page" element={<MuConfiguration />} />
          <Route path="municipalites/:cityCode/mu-configuration" element={<Navigate to={`${window.location.pathname}/mu-images`} replace />} />

          {/* <Route path="municipalites/:cityCode/mu-messages" element={<MuMessages />} /> */}
          <Route path="municipalites/:cityCode/mu-messages/:page" element={<MuMessages />} />
          <Route path="municipalites/:cityCode/mu-messages" element={<Navigate to={`${window.location.pathname}/mu-messages-list`} replace />} />
          <Route path="municipalites/:cityCode/mu-messages/edit-message/:idCityMessage" element={<MuEditMessage />} />


          <Route path="utilisateurs/:page" element={<Utilisateurs />} />
          {/* <Route path="utilisateurs" element={<Navigate to="/dashboard/utilisateurs/users-list" replace />} /> */}
          <Route path="utilisateurs" element={<Navigate to={`${window.location.pathname}/users-list`} replace />} />
          <Route path="utilisateurs/edit-user/:idUser" element={<EditOneUserOfAll />} />
          {/* {
            allowedRolesForUserManagement.includes(roleName) ? (
              <Route path="utilisateurs/edit-user/:idUser" element={<EditOneUserOfAll />} />
            ) : (
              <Route path="*" element={<ErrorPage />} />
            )
          } */}

          {/* <Route path="messages" element={<Messages />} /> */}
          <Route path="messages/:page" element={<Messages />} />
          <Route path="messages" element={<Navigate to={`${window.location.pathname}/messages-list`} replace />} />
          <Route path="messages/edit-message/:idCityMessage" element={<EditOneMessageOfAll />} />

          <Route path="profile" element={<Profile />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;