import React, { createContext, useContext, useState } from 'react'
// import decodeJwt from 'jwt-decode'
import axios from 'axios'
import { useLocation } from 'react-router';

const AuthContext = createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [currentUser, SetCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUserInfo')));

    // Créer une instance d'Axios pour les requêtes vers le serveur d'authentification
    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL, // URL du serveur d'authentification
    });

    // Intercepteur pour ajouter le jeton JWT à chaque requête
    authAxios.interceptors.request.use((config) => {
        // Récupérer le jeton JWT depuis le localStorage
        const { access_token } = JSON.parse(localStorage.getItem('portailclient')) || {};
        if (access_token) {
            // Ajouter le jeton JWT à l'en-tête Authorization de la requête
            config.headers.Authorization = `Bearer ${access_token}`;
        }
        return config;
    });

    // Intercepteur pour gérer les erreurs d'authentification
    authAxios.interceptors.response.use(
        (response) => response,
        async (error) => {

            const originalRequest = error.config;
            // Si la requête retourne une erreur 401 (non autorisé) et que ce n'est pas une requête pour obtenir un nouveau jeton JWT
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    // Obtenir un nouveau jeton JWT avec le token de rafraîchissement
                    const refresh_token = JSON.parse(localStorage.getItem('portailclient')).refresh_token;
                    const response = await authAxios.post('/auth/refreshtoken', { refresh_token });

                    // Stocker le nouveau jeton JWT dans le localStorage
                    localStorage.setItem('portailclient', JSON.stringify(
                        {
                            'access_token': response.data.access_token,
                            ...response.data
                        }));

                    // Ajouter le nouveau jeton JWT à l'en-tête Authorization de la requête
                    originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;

                    // Retourner la requête originale avec le nouveau jeton JWT
                    return authAxios(originalRequest);

                }
                catch (error) {
                    console.error('==>>', error);

                    // Si l'obtention d'un nouveau jeton JWT échoue, rediriger vers la page de connexion
                    // logout();
                    // window.location.href = '/';
                    //window.location.href = '/login';
                    return false;
                }
            }
            // Si la requête retourne une autre erreur que 401, la renvoyer telle quelle
            return Promise.reject(error);
        }
    );

    //!----------------------------------------------------------------
    //!----------------------------------------------------------------
    //!----------------------------------------------------------------

    const location = useLocation();

    async function forgotPW({ username, email }) {
        // Si l'identifiant username a été fourni.

        let response;
        if (username) {
            try {
                response = await authAxios.get('/user/check-username', {
                    params: {
                        username: username
                    }
                });
                return response.data; // Renvoie les données récupérées depuis la requête
            } catch (error) {
                console.error("Erreur lors de la requête GET '/user/check-username':", error);
                throw error; // Renvoie l'erreur
            }
        }
        // Le paramètre 'email' a été fourni.
        else if (email) {
            try {
                response = await authAxios.get('/user/check-email', {
                    params: {
                        email: email
                    }
                });
                return response.data; // Renvoie les données récupérées depuis la requête
            } catch (error) {
                console.error("Erreur lors de la requête GET '/user/check-email':", error);
                throw error; // Renvoie l'erreur
            }
        }
    };


    async function sendEmail({ username, fullName, email }) {
        // console.log(username, fullName, email)
        // Si l'identifiant username et le email ont été fournis.
        if (username && email) {
            try {
                const response = await authAxios.post('/user/send-fpw-email', {
                    username: username,
                    fullName: fullName,
                    email: email,
                });
                return response.data; // Renvoie les données récupérées depuis la requête
            } catch (error) {
                console.error("Erreur lors de la requête GET '/user/send-fpw-email':", error);
                throw error; // Renvoie l'erreur
            }
        }

    }


    // Fonction pour valider le token en utilisant une requête POST
    async function validateToken(token, newPassword) {
        // console.log({ token, newPassword });
        try {
            const response = await authAxios.post('/user/reset-password', {
                token,
                newPassword
            });

            // console.log({ response });

            return response; // Renvoie la réponse récupérée depuis la requête

        } catch (error) {
            console.log(error)
            console.error("Erreur lors de la requête POST '/user/reset-password':", error.response.data.error);
        }
    }

    //!----------------------------------------------------------------
    //!----------------------------------------------------------------
    //!----------------------------------------------------------------

    async function login(username, password) {

        try {
            const response = await authAxios.post('/auth/token', { username, password });

            if (!response) {
                return false;
            }

            // console.log('response : ', response.data.roles[0].name === 'ROLE_BANNI')
            //! console.log('response : ', response.data)

            if (response.data) {
                if (response.data.roles.length > 0 && response.data.roles[0].name !== 'ROLE_BANNI') {
                    try {

                        localStorage.setItem('selectedMenuItem', JSON.stringify(
                            {
                                idMenu: 0,
                                menuItemSelected: location.pathname
                            }
                        ));

                        localStorage.setItem('portailclient', JSON.stringify(
                            {
                                // 'access_token': response.data.access_token,
                                // 'refresh_token': response.data.refresh_token,
                                ...response.data
                            }
                        ));


                        const res = await authAxios.get('/user', {
                            headers: {
                                'Authorization': `Bearer ${response.data.access_token} `
                            }
                        });

                        // console.log('res : ', res.data)

                        // Regrouper les groupes par utilisateur sans doublant distinctement
                        const resultat = res.data.reduce((acc, current) => {
                            // Vérifier si l'objet est vide pour la première entrée
                            if (Object.keys(acc).length === 0) {
                                // Créer l'objet utilisateur avec idGroupTab contenant un tableau de l'idGroup actuel
                                return {
                                    ...current,
                                    idGroupTab: [current.idGroup],
                                };
                            } else {
                                // Ajouter l'idGroup au tableau existant dans acc.idGroupTab s'il n'est pas déjà présent
                                if (!acc.idGroupTab.includes(current.idGroup)) {
                                    acc.idGroupTab.push(current.idGroup);
                                }
                                return acc;
                            }
                        }, {}); // On initialise acc avec un objet vide

                        localStorage.setItem('currentUserInfo', JSON.stringify(resultat));
                        // localStorage.setItem('currentUserInfo', JSON.stringify({ ...res.data[0] }));

                        SetCurrentUser(JSON.parse(localStorage.getItem('currentUserInfo')));

                    } catch (error) {
                        console.error("Erreur lors de la requête GET '/user':", error);
                    }
                }
            }
        } catch (error) {
            console.error("Erreur lors de la requête POST '/auth/token':", error);
        }
    };

    function logout() {
        SetCurrentUser(null);
        localStorage.clear();
    }

    const value = {
        currentUser,
        login,
        logout,
        forgotPW,
        validateToken,
        sendEmail,
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
