import dataService from '../../httpService';

// Fonction pour obtenir les municipalités et les clients particuliers de l'utilisateur
const getLandingPageData = async (gde, currentUserInfo) => {
  try {
    const response = await dataService.get('/user/landingPageData', { params: { gde, currentUserInfo } });
    //     const response = await dataService.get('/user/landingPageData', {
    //       params: { gde, currentUserInfo: JSON.stringify(currentUserInfo) }
    //     });
    return response.data;
  } catch (error) {
    throw error;
  }
}


// Exporter les fonctions
export { getLandingPageData };

