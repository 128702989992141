import React, { useState, useMemo, useEffect } from "react";
import {
    Box,
    Button,
    // IconButton,
} from '@mui/material';
import { Tooltip } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import './GridTable.scss';
// import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
// import { Link } from "react-router-dom";
import DialogConfirm from './DialogConfirm';
// import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function CustomToolbar({ setFilterButtonRef, isSelectionEmpty, onClickDelete }) {
    const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

    return (
        <div className='grid-customToolbar-container'>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton ref={setFilterButtonRef} />
                <GridToolbarExport
                    csvOptions={{
                        fileName: `Liste-Mise-en-Ligne-${currentCity.description}-${currentCity.cityName}`,
                        delimiter: ';',
                        utf8WithBom: true,
                        columnsToExport: ['idTracking', 'demandeur', 'description', 'statusName', 'dateMaj', 'dateMel'],
                    }}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>

            <div className="btn-supprimer">
                {!isSelectionEmpty && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClickDelete}
                    >
                        Supprimer
                    </Button>
                )}
            </div>
        </div>
    );
}
CustomToolbar.propTypes = {
    setFilterButtonRef: PropTypes.func.isRequired,
    isSelectionEmpty: PropTypes.bool.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

export default function GridTable({ data, allowedManagement }) {

    const [isSelectionEmpty, setIsSelectionEmpty] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);


    useEffect(() => {
        // const rowsWithIds = data.map((row, index) => ({ ...row, id: index }));
        // setRows(rowsWithIds)
        setRows(data)
    }, [data]);

    const columns = useMemo(() => {
        const baseColumns = [
            // { field: 'idTracking', headerName: 'ID MAJ', flex: 0.5, editable: false, },
            { field: 'demandeur', headerName: 'Demandeur', flex: 1, editable: false, },
            { field: 'description', headerName: 'Type', flex: 0.8, editable: false, },
            //Ajout d'une colonne pour tester l'affichage d'une info-bulle simple
            // {
            //     field: 'info',
            //     headerName: '',
            //     flex: 1,
            //     renderCell: (params) => {
            //         // Vérifiez si la condition est remplie
            //         if (params.row.dateMel !== null || params.row.idStatus !== 1) {
            //             return (
            //                 <Tooltip title="Votre demande est en cours de traitement ou déjà finalisée, la suppression est impossible">
            //                     <span
            //                         aria-label={`Information sur ${params.row.demandeur}`}
            //                         style={{
            //                             display: 'flex',
            //                             justifyContent: 'center',
            //                             alignItems: 'center',
            //                             width: '100%',
            //                             height: '100%',
            //                             cursor: 'pointer',
            //                         }}
            //                     >
            //                         <ErrorOutlineIcon style={{ color: '#B0BEC5' }} />
            //                     </span>
            //                 </Tooltip>
            //             );
            //         }
            //         return null; // Si la condition n'est pas remplie, retourner null
            //     },
            //     isRowSelectable: (rowParams) => rowParams.row.dateMel === null && rowParams.row.idStatus === 1,
            // },
            // { field: 'statusName', headerName: 'Statut', flex: 1, editable: false, },
            {
                field: 'statusName',
                headerName: 'Statut',
                flex: 1.2,
                renderCell: (params) => {
                    // Vérifiez si la condition est remplie
                    const isInfoVisible = params.row.dateMel !== null || params.row.idStatus !== 1;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', width: '100%', height: '100%' }}>
                            {(isInfoVisible && allowedManagement) && (
                                <Tooltip title="Votre demande est en cours de traitement ou est déjà finalisée, aucune opération de suppression ou d'édition n'est possible">
                                    <span
                                        aria-label={`Information sur ${params.row.demandeur}`}
                                        style={{
                                            cursor: 'pointer',
                                            marginRight: '8px', // Espacement entre l'icône et le texte
                                        }}
                                    >
                                        <ErrorOutlineIcon style={{ color: '#B0BEC5' }} />
                                    </span>
                                </Tooltip>
                            )}
                            <span>{params.row.statusName}</span> {/* Affiche le statut ici */}
                        </div>
                    );
                },
                isRowSelectable: (rowParams) => rowParams.row.dateMel === null && rowParams.row.idStatus === 1,
            },
            {
                field: 'dateMaj', headerName: 'Mise à jour', flex: 1.5, editable: false,
                renderCell: (params) => (
                    <>
                        {/* Utilisation de moment.utc() pour corriger le décalage */}
                        <span>{moment.utc(params.value).format('ddd DD MMM YYYY')}</span>
                        <span>&nbsp;à&nbsp;</span>
                        {/* <span>{moment.utc(params.value).format('HH:mm:ss')}</span> */}
                        <span>{moment.utc(params.value).format('HH:mm')}</span>
                    </>
                )
            },
            {
                field: 'dateMel', headerName: 'Mise en ligne', flex: 1.5, editable: false,
                renderCell: (params) => {
                    const isValidDate = moment.utc(params.value).isValid(); // Vérification de la validité de la date
                    return (
                        <>
                            {isValidDate ? (
                                <>
                                    {/* Utilisation de moment.utc() pour corriger le décalage */}
                                    <span>{moment.utc(params.value).format('ddd DD MMM YYYY')}</span>
                                    <span>&nbsp;à&nbsp;</span>
                                    {/* <span>{moment.utc(params.value).format('HH:mm:ss')}</span> */}
                                    <span>{moment.utc(params.value).format('HH:mm')}</span>
                                </>
                            ) : (
                                <span>Requête en cours</span> // Message affiché en cas de date non valide
                            )}
                        </>
                    );
                }
            },
        ];
        // Edition d'une mise en ligne
        // if (allowedManagement) {
        //     // .unshift Ajouter la colonne d'édition uniquement pour le rôle 'admin' au debut du tableau
        //     baseColumns.unshift({
        //         field: 'edit',
        //         headerName: 'Éditer',
        //         width: 80,
        //         editable: false,
        //         sortable: false,
        //         filterable: false,
        //         align: 'center',
        //         renderCell: (params) => {
        //             const encodedIdUser = encodeURIComponent(params.row.idUser);
        //             // Afficher l'idUser dans la console
        //             // console.log(params.row.idUser);
        //             // console.log(encodedIdUser);

        //             // Retourner le JSX
        //             return (
        //                 <Link
        //                     to={`/dashboard/utilisateurs/edit-user/${encodedIdUser}`}
        //                     style={{
        //                         textDecoration: "none",
        //                         cursor: "pointer",
        //                     }}
        //                 >
        //                     <IconButton className="edit-user">
        //                         <EditIcon className="edit-icon" />
        //                     </IconButton>
        //                 </Link>
        //             );
        //         },
        //     });
        // }

        return baseColumns;
        // }, [allowedManagement]);
    }, []);

    const handleSelectionChange = (selection) => {
        setIsSelectionEmpty(selection.length === 0);
        setSelectedRows(selection);
    };

    const onClickDelete = () => {
        setOpen(true)
    };




    return (
        <div className="GridTable">
            {open && (
                <DialogConfirm
                    open={open}
                    setOpen={setOpen}
                    rows={rows}
                    selectedRows={selectedRows}
                    setRows={setRows}
                    setIsSelectionEmpty={setIsSelectionEmpty}
                />
            )}

            <Box
                className='dataGridBox'
                sx={{
                    width: '100%',
                }}
            >
                <DataGrid
                    slots={{ toolbar: CustomToolbar, }}

                    slotProps={{
                        panel: {
                            anchorEl: filterButtonRef,
                        },
                        toolbar: {
                            setFilterButtonRef,
                            isSelectionEmpty,
                            onClickDelete,
                        },
                    }}

                    initialState={{ pagination: { paginationModel: { pageSize: 10 } }, }}
                    pageSizeOptions={[10, 50, 100]}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.idTracking}
                    disableRowSelectionOnClick

                    checkboxSelection={allowedManagement}

                    // Les cases à cocher ne seront actives que pour les lignes où condition est vrifiée
                    isRowSelectable={(rowParams) => rowParams.row.dateMel === null && rowParams.row.idStatus === 1}

                    onRowSelectionModelChange={allowedManagement ? handleSelectionChange : undefined}

                />
            </Box>
        </div>
    )
}