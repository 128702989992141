import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { InputAdornment } from '@mui/material';
import {
  checkUserPassword,
  updateUserInfo,
} from './httpQuerys';
import { useNavigate } from 'react-router';
// import { useAuth } from "../../contexts/AuthContext";


export default function DialogConfirm({ userToEdit, open, setOpen, isFormValidated }) {

  const navigate = useNavigate();
  // const { logout } = useAuth();

  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(open);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false);
  };

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    setPassword(e.target.value)
    if (e.target.value === '')
      setErrorPassword(false)
  }

  //-------------------pour rendre le password visible ou non ---------------------------
  const [showPassword, setShowPassword] = useState(false);
  const handleClickPassword = () => {
    setShowPassword(!showPassword)
  }

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  const handleConfirmationClose = () => {
    navigate(`/dashboard`); // Rediriger vers la page dashboard
    setShowConfirmationDialog(false)
    // logout();
  }

  const handleConfirm = async () => {
    // Vérification du mot de passe de l'utilisateur
    const passwordMatch = await checkUserPassword(password);

    if (passwordMatch) {
      try {
        const userUpdated = await updateUserInfo(userToEdit)

        if (userUpdated) {
          console.log('Mise à jour effectuée.');
          setMessageConfirmationDialog('Mise à jour effectuée avec succès.')
          setShowConfirmationDialog(true); // Afficher la boîte de dialogue de confirmation
          setOpenDialog(false);

          setTimeout(() => {
            handleConfirmationClose(); // Fermer la boîte de dialogue après 3 secondes
          }, 3000);
        }
        else {
          console.log('Erreur de la Mise à jour !');
          setMessageConfirmationDialog('Erreur lors de la mise à jour !')
          setShowConfirmationDialog(true)
        }

      } catch (error) {
        // Gérer les erreurs de mise à jour
        console.log('Erreur de la Mise à jour !');
        setMessageConfirmationDialog('Erreur lors de la mise à jour !')
        setShowConfirmationDialog(true)
      }
    } else {
      setErrorPassword(true);
    }
  };

  return (
    isFormValidated ? (
      <div>
        <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
          <DialogTitle>Confirmer les modifications</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Pour confirmer les modification à apporter à l'utilisateur <strong><em>{userToEdit.fullName}</em></strong> vous êtes demandé
              d'entrer votre mot de passe par mesures de sécurité ! Cette opération est définitive.
            </DialogContentText>

            <TextField
              id="confirmation"
              name="confirmation"
              label="Mot de passe"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              variant="standard"
              autoFocus
              margin="dense"

              InputProps={{
                style: { width: 300, color: 'blue' },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleClickPassword}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}

              value={password}
              onChange={handleChange}

              error={errorPassword}
              FormHelperTextProps={{ sx: { color: 'red' } }}
            />
            {errorPassword && (
              <FormHelperText error={errorPassword}>Mot de passe invalide</FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color='error'>ANNULER</Button>
            <Button onClick={handleConfirm}>CONFIRMER</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showConfirmationDialog} onClose={handleConfirmationClose}>
          <DialogTitle>Confirmation de la mise à jour</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {messageConfirmationDialog}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmationClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : (
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Erreurs dans le formulaire</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Un ou plusieurs champs du forulaire
              de l'utilisateur <strong><em>{userToEdit.fullName}</em></strong> sont manquants ou érronés.
              Veuillez vérifier vos données !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color='error'>Retour au Formulaire</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  )
}
