import dataService from "../../../httpService";

// Fonction pour obtenir les mises en ligne d'une municipalité
async function getMiseEnLigneData() {

  const { cityCode, mrcCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  try {
    const response = await dataService.get('/mise-en-ligne/municipalitie/get-list-mel', {
      params: {
        cityCode: cityCode,
        mrcCode: mrcCode
      }
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir les mises en ligne d'une municipalité
async function getMiseEnLigneType() {
  try {
    const response = await dataService.get('/mise-en-ligne/get-type-mel');
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir les sous-types d'une mise en ligne d'une municipalité
async function getMiseEnLigneSubDataType() {
  try {
    const response = await dataService.get('/mise-en-ligne/get-sub-data-type-mel');
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir la liste de tous les profils
async function getProfileList(idGroup) {
  try {
    const response = await dataService.get('/user/profile-list', {
      params: {
        idGroup
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Fonction pour obtenir la liste de tous les profils
async function getServicesProfile(cityCode, idGroup, nameProfile) {
  try {
    const response = await dataService.get('/user/services-profile-list', {
      params: {
        cityCode, idGroup, nameProfile
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}


// Fonction pour ajouter une mises en ligne d'une municipalité
async function addNewMiseEnLigne(formDataMEL) {
  try {
    // Envoi de formDataMEL
    const responseMEL = await dataService.post('/mise-en-ligne/municipalitie/addnew-mel', formDataMEL);
    // console.log('Réponse de formDataMEL:', responseMEL.data); // Déboguer la réponse


    return { responseMEL: responseMEL.data };
  } catch (error) {
    console.error('Erreur lors de l\'envoi:', error);
    throw error; // Lancer l'erreur pour gestion ultérieure
  }
}

//! Fonction pour envoyer au serveur les fichiers d'une mises en ligne d'une municipalité
async function sendFilesMiseEnLigne(formDataFilesToSend) {
  try {
    // Envoi de formDataFilesToSend
    const responseFiles = await dataService.post('/mise-en-ligne/municipalitie/send-files-mel', formDataFilesToSend);
    // console.log('Réponse de formDataFilesToSend:', responseFiles.data); // Déboguer la réponse

    return { responseFiles: responseFiles.data };
  } catch (error) {
    console.error('Erreur lors de l\'envoi:', error);
    throw error; // Lancer l'erreur pour gestion ultérieure
  }
}

// Fonction pour récuprer les utilisateur qu'on peut leur attribuer un nouveau service
async function getUsersListForContact(cityCode, idGroup) {
  try {
    const response = await dataService.get('/mise-en-ligne/users-list-for-contact', {
      params: {
        cityCode, idGroup
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}



// Fonction pour supprimer une mises en ligne d'une municipalité
async function deleteSelectedOneMiseEnLigneOfAll(idTracking) {

  try {
    const response = await dataService.delete('/mise-en-ligne/municipalitie/delete-one-off-all-mel', {
      params: { idTracking }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}




// Exporter les fonctions
export {
  getMiseEnLigneData,
  getMiseEnLigneType,
  getMiseEnLigneSubDataType,
  addNewMiseEnLigne,
  getUsersListForContact,
  getProfileList,
  getServicesProfile,
  sendFilesMiseEnLigne,
  deleteSelectedOneMiseEnLigneOfAll
};