import React, { useRef, useState, useEffect } from 'react';
import './Card.scss';
import { Box, Typography, List, ListItem, IconButton } from '@mui/material';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

// Fonction pour obtenir les types de fichiers acceptés
const getFileAcceptType = (typeMaj, constSubTypeMAJ, idSubDataTypeMaj) => {

    // console.log(idSubDataTypeMaj)
    let fileTypes = '';
    let fileTypesMessage = '';

    // Détermination des types de fichiers acceptés en fonction du type de mise à jour
    switch (typeMaj) {
        case 'Rôle':
            fileTypes = '.mdb, .xml, .zip, .rar';
            fileTypesMessage = 'Type(s) de fichiers autorisés : .mdb, .xml, .zip, .rar';
            break;
        case 'Matrice':
            if (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice || idSubDataTypeMaj === constSubTypeMAJ.GML) {
                fileTypes = '.mdb, .zip, .rar';
                fileTypesMessage = 'Type(s) de fichiers autorisés : GOmatriceXXXXX.mdb, GOmatriceHabXXXXX.mdb, GOmatriceHisXXXXX.mdb, .zip, .rar';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.GML) {
                fileTypes = '.zip, .rar'; // Aucune restriction sur les types de fichier
                fileTypesMessage = 'Tous les types de fichiers sont acceptés, les fichiers .zip, .rar sont suggérés';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Autre) {
                fileTypes = '.txt, .pdf, .mdb, .xml, .zip, .rar'; // Aucune restriction sur les types de fichier
                fileTypesMessage = 'Tous les types de fichiers sont acceptés, les fichiers .zip sont suggérés';
            }
            break;
        case 'Zonage':
            if (idSubDataTypeMaj === constSubTypeMAJ.Zone) {
                fileTypes = '.zip, .rar';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .zip, .rar';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Grille) {
                fileTypes = '.pdf,.zip, .rar';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .pdf, .zip, .rar';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Reglement) {
                fileTypes = '.pdf';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .pdf';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.PIIA) {
                fileTypes = '.pdf';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .pdf';
            } else if (idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation) {
                fileTypes = '.pdf';
                fileTypesMessage = 'Type(s) de fichiers autorisés : .pdf';
            }
            break;
        case 'Infrastructure':
            if (idSubDataTypeMaj === constSubTypeMAJ.GOinfra) {
                fileTypesMessage = 'C’est une requête de mise en ligne dans GOnet.';
            } else if (idSubDataTypeMaj === -constSubTypeMAJ.GOinfra) {
                fileTypes = '.zip, .rar';
                fileTypesMessage = 'Il s’agit de la transmission de nouvelles données. Type(s) de fichiers autorisés : .zip, .rar';
            }
            break;
        // case 'Orthophoto':
        // case 'Photos':
        // case 'Croquis':
        //     // fileTypes = '...';
        //     fileTypesMessage = 'Les photos et croquis sont trop volumineux pour être transférés à partir de cette page. Indiquez où les déposer pour récupération par le soutien technique d\'AZIMUT.';
        //     break;
        case 'Autre':
            fileTypes = '.shp,.gdb,.mxd,.gpkg, .zip, .rar';
            fileTypesMessage = 'Type(s) de fichiers autorisés : .shp, .gdb, .mxd, .gpkg, .zip, .rar';
            break;
        case 'Transactions immobilières':
            fileTypes = '.xlsx, .xml, .zip, .rar';
            fileTypesMessage = 'Type(s) de fichiers autorisés : .xlsx, .xml, .zip, .rar';
            break;
        default:
            // fileTypes = ''; // Par défaut, aucun fichier accepté
            fileTypesMessage = '';
    }

    return { fileTypes, fileTypesMessage };
};


export default function Card({ formDataMEL, setFormDataMEL, formErrors, setFormErrors, typeMaj, constSubTypeMAJ, idSubDataTypeMaj, fichiers, setFichiers }) {

    const [inputKey, setInputKey] = useState(Date.now());
    const [messageTypeFiles, setMessageTypeFiles] = useState('');
    const [acceptedFileTypes, setAcceptedFileTypes] = useState('');
    const refInput = useRef(null);

    // Mettre à jour les types de fichiers autorisés lorsque `typeMaj` change
    useEffect(() => {
        const { fileTypes, fileTypesMessage } = getFileAcceptType(typeMaj, constSubTypeMAJ, idSubDataTypeMaj);
        setMessageTypeFiles(fileTypesMessage);
        setAcceptedFileTypes(fileTypes);
    }, [typeMaj, idSubDataTypeMaj, constSubTypeMAJ]);

    const handleUploadClick = () => {
        refInput.current.click();
    };

    // const handleDrop = (event) => {
    //     event.preventDefault();
    //     const droppedFiles = Array.from(event.dataTransfer.files);

    //     // Vérification des types de fichiers acceptés
    //     const validFiles = droppedFiles.filter(file => {
    //         const fileExtension = file.name.split('.').pop();
    //         return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
    //     });

    //     // Mise à jour des fichiers dans le parent uniquement avec les fichiers valides
    //     const updatedFiles = [...fichiers, ...validFiles];
    //     setFichiers(updatedFiles);
    // };
    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);

        setFormErrors((prevState) => ({ ...prevState, fichiers: '' }))

        // Filtrer les doublons
        const filteredFiles = droppedFiles.filter(newFile => {
            return !fichiers.some(existingFile => existingFile.name === newFile.name && existingFile.size === newFile.size);
        });

        let validFiles = [];
        // Condition spécifique pour 'Matrice' avec types 'GOmatrice' ou 'GML'
        // if (typeMaj === 'Matrice' && (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice || idSubDataTypeMaj === constSubTypeMAJ.GML)) {

        // Condition spécifique pour 'Matrice' avec types 'GOmatrice'
        if (typeMaj === 'Matrice' && (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice)) {
            validFiles = filteredFiles.filter(file => {
                const fileExtension = file.name.split('.').pop();
                const fileName = file.name;

                // Vérification de l'extension .zip ou du format de nom .mdb
                const isValidZip = fileExtension === 'zip';
                const isValidRar = fileExtension === 'rar';
                const isValidMdb = fileExtension === 'mdb' && (
                    /^GOmatrice\d{5}\.mdb$/.test(fileName) || /^GOmatriceHab\d{5}\.mdb$/.test(fileName) || /^GOmatriceHis\d{5}\.mdb$/.test(fileName)
                );

                return isValidZip || isValidMdb || isValidRar;
            });
        }

        // Condition spécifique pour 'Zonage' avec types 'Règlements' ou 'Guide d’interprétation'
        else if (typeMaj === 'Zonage' && (idSubDataTypeMaj === constSubTypeMAJ.Reglement || idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation)) {
            validFiles = filteredFiles.filter(file => {
                const fileExtension = file.name.split('.').pop();
                return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
            });

            // Limiter à un seul fichier valide
            if (validFiles.length > 0) {
                setFichiers([validFiles[0]]); // Accepte uniquement le premier fichier valide
                return; // Sortie de la fonction après la mise à jour
            }
        }
        // Cas par défaut pour d'autres types avec vérification des types de fichiers acceptés
        else {
            validFiles = filteredFiles.filter(file => {
                const fileExtension = file.name.split('.').pop();
                return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
            });
        }

        // Mise à jour des fichiers uniquement si des fichiers valides ont été trouvés
        if (validFiles.length > 0) {
            setFichiers([...fichiers, ...validFiles]);
        }
    };


    //================================================================

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    //================================================================

    const handleChangeFile = (e) => {
        const selectedFiles = Array.from(e.target.files);

        // Changer la clé de l'input pour forcer un re-render
        setInputKey(Date.now());

        setFormErrors((prevState) => ({ ...prevState, fichiers: '' }))

        // Filtrer les doublons
        const filteredFiles = selectedFiles.filter(newFile => {
            return !fichiers.some(existingFile => existingFile.name === newFile.name && existingFile.size === newFile.size);
        });

        let validFiles = [];

        // Condition spécifique pour 'Matrice' avec types 'GOmatrice'
        if (typeMaj === 'Matrice' && (idSubDataTypeMaj === constSubTypeMAJ.GOmatrice)) {
            validFiles = filteredFiles.filter(file => {
                const fileExtension = file.name.split('.').pop();
                const fileName = file.name;

                // Vérification de l'extension .zip ou du format de nom .mdb
                const isValidZip = fileExtension === 'zip';
                const isValidRar = fileExtension === 'rar';
                const isValidMdb = fileExtension === 'mdb' && (
                    /^GOmatrice\d{5}\.mdb$/.test(fileName) || /^GOmatriceHab\d{5}\.mdb$/.test(fileName) || /^GOmatriceHis\d{5}\.mdb$/.test(fileName)
                );

                return isValidZip || isValidMdb || isValidRar;
            });
        }

        // Condition spécifique pour 'Zonage' avec types 'Règlements' ou 'Guide d’interprétation'
        else
            if (typeMaj === 'Zonage' && (idSubDataTypeMaj === constSubTypeMAJ.Reglement || idSubDataTypeMaj === constSubTypeMAJ.Guidedinterpretation)) {
                validFiles = filteredFiles.filter(file => {
                    const fileExtension = file.name.split('.').pop();
                    return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
                });

                // Limiter à un seul fichier valide
                if (validFiles.length > 0) {
                    setFichiers([validFiles[0]]); // Accepte uniquement le premier fichier valide
                    return; // Sortie de la fonction après la mise à jour
                }
            }
            // Cas par défaut pour d'autres types avec vérification des types de fichiers acceptés
            else {
                validFiles = filteredFiles.filter(file => {
                    const fileExtension = file.name.split('.').pop();
                    return acceptedFileTypes.split(',').some(type => type.trim().includes(fileExtension));
                });
            }

        // Mise à jour des fichiers uniquement si des fichiers valides ont été trouvés
        if (validFiles.length > 0) {
            setFichiers([...fichiers, ...validFiles]);
        }
    };



    const handleDeleteFile = (index) => {
        const updatedFiles = fichiers.filter((_, i) => i !== index);
        setFichiers(updatedFiles); // Mise à jour des fichiers dans le parent
    };

    return (
        <div>
            <div className="file-card">
                <div className="titre-image"><em>Fichier(s) à déposer</em></div>

                <section className="file-inputs" onClick={handleUploadClick}>
                    <input
                        type="file"
                        multiple
                        disabled={!typeMaj || messageTypeFiles === ''}
                        ref={refInput}
                        key={inputKey}
                        onChange={handleChangeFile}
                        accept={acceptedFileTypes}
                        // accept={acceptedFileTypes === '*' ? '' : acceptedFileTypes} // Si accept est '*', on le supprime pour accepter tous les fichiers
                        style={{ display: 'none' }}
                    />
                    <Box
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        sx={{
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <Typography variant="body2" color="textSecondary">
                            Glissez-déposez vos fichiers ici ou cliquez pour sélectionner <br /><br />
                            {messageTypeFiles === '' ? 'Aucun type de fichier n\'est accepté.' : messageTypeFiles}
                        </Typography>
                    </Box>
                </section>

                {fichiers.length > 0 ? (
                    <List>
                        {fichiers.map((file, index) => (
                            <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FilePresentRoundedIcon />
                                <Typography sx={{ marginLeft: '10px', flexGrow: 1 }}>
                                    {file.name}
                                </Typography>
                                <IconButton onClick={() => handleDeleteFile(index)}>
                                    <DeleteForeverRoundedIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="caption" color="error">
                        {formErrors.fichiers}
                    </Typography>
                )}
            </div>
        </div>
    );
}
