import DOMPurify from 'dompurify';
import { checkUserNameUniqueness } from './httpQuerys'

export const transformResultToUserToEdit = (result) => {
    // Initialisation de l'objet userToEdit avec les propriétés communes
    const userToEdit = {
        idUser: result[0].idUser,
        password: result[0].password,
        fullName: result[0].fullName,
        email: result[0].email,
        Description: result[0].Description,
        telephone: result[0].telephone,
        ext: result[0].ext,
        genre: result[0].genre,
        roleName: result[0].roleName,
        idRole: result[0].idRole,
        idtitle: result[0].idtitle,
        iddepartment: result[0].iddepartment,
        department: result[0].department,
        title: result[0].title,
        profiles: []
    };

    // Création de la structure des profils et services
    result.forEach(row => {
        const userProfile = {
            idUserProfile: row.idUserProfile,
            idGroup: row.idGroup,
            cityCode: row.cityCode,
            cityName: row.cityName,
            logo: row.logo,
            profile: row.profile,
            creationUserProfile: row.creationUserProfile,
            expiration: row.expiration,
            contact: row.contact,
            servicesProfile: []
        };

        const serviceProfile = {
            idService: row.idService,
            idServiceType: row.idServiceType,
            defaultService: row.defaultService,
            aliasName: row.aliasName
        };

        // Vérification si le profil existe déjà dans userToEdit.profiles
        const existingProfile = userToEdit.profiles.find(profile => profile.idUserProfile === row.idUserProfile);

        if (existingProfile) {
            // Si le profil existe, ajouter le service au profil existant
            existingProfile.servicesProfile.push(serviceProfile);
        } else {
            // Si le profil n'existe pas, ajouter le profil et le service
            userProfile.servicesProfile.push(serviceProfile);
            userToEdit.profiles.push(userProfile);
        }
    });

    return userToEdit;
}

export const validateEmail = async (value, setFieldError) => {
    const sanitizedValue = DOMPurify.sanitize(value);

    if (!sanitizedValue.trim()) {
        setFieldError("L'adresse e-mail est obligatoire.");
        return false;
    }

    // Vérification de la syntaxe de l'adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(sanitizedValue)) {
        setFieldError("Veuillez fournir une adresse e-mail valide.");
        return false;
    }

    // const verifyUniqueEmail = async (sanitizedValue) => {
    //     return new Promise(async (resolve) => {
    //         try {
    //             // Vérification de l'unicité de l'adresse e-mail
    //             const isEmailUnique = await checkUserEmailUniqueness(sanitizedValue);
    //             // console.log('isEmailUnique ==> ', isEmailUnique[0].duplicatedEmail)
    //             if (isEmailUnique[0].duplicatedEmail > 0) {
    //                 setFieldError("Cette adresse e-mail est déjà utilisée.");
    //                 resolve(false);
    //             } else {
    //                 setFieldError('');
    //                 resolve(true);
    //             }
    //         } catch (error) {
    //             // Gestion de l'erreur de la requête
    //             // console.error(error);
    //             setFieldError("Une erreur s'est produite lors de la vérification de l'adresse e-mail.");
    //             resolve(false);
    //         }
    //     });
    // };

    // const isEmailUnique = await verifyUniqueEmail(sanitizedValue);
    // // console.log({ isEmailUnique })

    // // Validation réussie
    // return isEmailUnique;

    // Validation réussie
    setFieldError('');
    return true;
};

export const validatePassword = (password, setPasswordError, setPasswordStrength) => {
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    // (?=.* [a - z]): Assure que la chaîne contient au moins une lettre minuscule.
    // (?=.* [A - Z]): Assure que la chaîne contient au moins une lettre majuscule.
    // (?=.*\d): Assure que la chaîne contient au moins un chiffre.
    // (?=.* [@$! %*?&#]): Assure que la chaîne contient au moins un caractère spécial parmi ceux spécifiés(@$! %*?&#).
    // [A - Za - z\d @$!%*?&#]{ 8,}: Indique que la chaîne doit contenir entre 8 et un nombre illimité de caractères parmi les lettres majuscules, les lettres minuscules, les chiffres, et les caractères spéciaux spécifiés.

    const passwordRegex = /^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/;
    // (?=.* [A - Za - z]): Assure que la chaîne contient au moins une lettre(majuscule ou minuscule).
    // [A - Za - z\d]{ 6,}: Indique que la chaîne doit contenir au moins 6 caractères parmi les lettres majuscules et minuscules non accentuées(A - Z, a - z) et les chiffres(0 - 9).

    const sanitizedPassword = DOMPurify.sanitize(password);
    const isPasswordValid = passwordRegex.test(sanitizedPassword);

    if (!sanitizedPassword.trim()) {
        setPasswordError("Mot de passe obligatoire !");
        setPasswordStrength('Faible');
        return false;
    }

    // Vérification de la présence de caractères spéciaux
    const specialCharRegex = /[^A-Za-z\d]/;
    if (specialCharRegex.test(sanitizedPassword)) {
        setPasswordError("Les caractères spéciaux ne sont pas acceptés !");
        setPasswordStrength('Faible');
        return false;
    }

    if (!isPasswordValid) {
        if (sanitizedPassword.length < 5) {
            setPasswordError("Mot de passe Faible !");
            setPasswordStrength('Faible');
            return false;
        }
        else {
            const passwordErrorMessage = "Mot de passe Moyen !";
            // Au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre, au moins un caractère spécial parmi @$!%*?&#, et une longueur minimale de 8 caractères.
            setPasswordError(passwordErrorMessage);
            setPasswordStrength('Moyen');
            return true;
        }
    }

    // Validation réussie
    setPasswordError('');
    setPasswordStrength('Élevé');
    return true;
};

export const validateField = async (value, fieldName, setFieldError) => {
    if (value.includes('<') || value.includes('>')) {
        setFieldError(`Le champ ${fieldName} doit contenir uniquement des lettres.`);
        return false;
    }

    const sanitizedValue = DOMPurify.sanitize(value);

    if (!sanitizedValue.trim()) {
        setFieldError(`Le champ ${fieldName} est obligatoire.`);
        return false;
    }

    if (!/^[a-zA-ZÀ-ÿ\s'-]+$/i.test(sanitizedValue)) {
        setFieldError(`Le champ ${fieldName} doit contenir uniquement des lettres.`);
        return false;
    }

    // Validation réussie
    setFieldError('');
    return true;
};

export const formatPhoneNumber = (value) => {
    if (!value) {
        return value;
    }

    const phoneNumber = value.replace(/[^\d]/g, '');

    if (phoneNumber.length < 4) return value;
    if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

// export const generateRandomPassword = () => {
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
// //     const passwordRegex = /^(?=.*[A-Za-z])[A-Za-z\d]{8,}$/;

// const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&';
// //     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let generatedPassword = '';

//     do {
//         generatedPassword = '';
// for (let i = 0; i < 8; i++) {
//             const randomIndex = Math.floor(Math.random() * characters.length);
//             generatedPassword += characters[randomIndex];
// }
//     } while (!passwordRegex.test(generatedPassword) );

//     return generatedPassword;
// };

export const generateRandomPassword = () => {
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const passwordRegex = /^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/;

    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let generatedPassword = '';

    do {
        generatedPassword = Array.from({ length: 6 }, () =>
            characters.charAt(Math.floor(Math.random() * characters.length))
        ).join('');
    } while (!passwordRegex.test(generatedPassword));

    return generatedPassword;
};


export const validateUserName = async (value, fieldName, setFieldError) => {
    const sanitizedValue = DOMPurify.sanitize(value);

    if (!sanitizedValue.trim()) {
        setFieldError(`Le champ ${fieldName} est obligatoire.`);
        return false;
    }

    const userNameRegex = /^[A-Za-z][A-Za-z\d@$!%*?&/\\()]{1,}$/;
    if (!userNameRegex.test(sanitizedValue)) {
        setFieldError(`L'${fieldName} doit contenir une chaîne de 2 caractères au minimum, qui commence par une lettre alphabétique. Elle peut contenir uniquement des caractères parmi A-Z, a-z, 0-9, @ $ ! % * ? & / \\ ( ) !`);
        return false;
    }

    const verifyUniqueUserName = async (sanitizedValue) => {
        return new Promise(async (resolve) => {
            try {
                const isUserNameUnique = await checkUserNameUniqueness(sanitizedValue);

                if (isUserNameUnique === 0) {
                    setFieldError('');
                    resolve(true);
                } else {
                    setFieldError("Cet identifiant est déjà utilisé.");
                    resolve(false);
                }
            } catch (error) {
                console.error(error);
                setFieldError("Une erreur s'est produite lors de la vérification du nom d'utilisateur.");
                resolve(false);
            }
        });
    };

    const isUserNameUnique = await verifyUniqueUserName(sanitizedValue);
    if (!isUserNameUnique) {
        setFieldError(`Cet identifiant est déjà utilisé.`);
        return false;
    }

    // Validation réussie
    setFieldError('');
    return true;
};

export async function generateUserName(prenom, nom) {
    // if (!prenom || !nom) {
    //     return {userName:'', fullName:'',};
    // }

    const generateUniqueUserName = async (prenom, nom) => {
        return new Promise((resolve) => {
            let userName = '';

            if (prenom && prenom.trim() !== '') {
                const prenomArray = prenom.trim().split(' ');
                if (prenomArray.length === 1) {
                    userName += prenomArray[0][0].toLowerCase();
                } else {
                    prenomArray.forEach((mot) => {
                        userName += mot[0].toLowerCase();
                    });
                }
            }

            if (nom && nom.trim() !== '') {
                const nomArray = nom.trim().split(' ');
                if (nomArray.length === 1) {
                    userName += nomArray[0].toLowerCase();
                } else {
                    nomArray.forEach((mot, index) => {
                        if (index < nomArray.length - 1) {
                            userName += mot[0].toLowerCase();
                        } else {
                            userName += mot.toLowerCase();
                        }
                    });
                }
            }
            resolve(userName);
        });
    };

    let generatedUserName = await generateUniqueUserName(prenom, nom);
    // let duplicateCountPromise = checkUserNameUniqueness(generatedUserName);
    // let duplicateCount = await duplicateCountPromise;

    // if (duplicateCount > 0) {
    //     generatedUserName += String(duplicateCount);
    // }

    const fullNameArray = `${prenom.trim()} ${nom.trim()}`.split(' ');

    let generatedFullName = '';
    fullNameArray.forEach((mot, index) => {
        if (mot.trim() !== '') {
            generatedFullName += mot.charAt(0).toUpperCase() + mot.slice(1).toLowerCase() + ' ';
        }
    });
    generatedFullName = generatedFullName.trim();

    return { userName: generatedUserName, fullName: generatedFullName };;
}

const Utils = {
    validateEmail,
    validatePassword,
    validateField,
    formatPhoneNumber,
    generateRandomPassword,
    validateUserName,
    generateUserName,
    transformResultToUserToEdit,
}
export default Utils;