import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';

export default function DialogConfirm({ handleClearAllFields, multiMuMessageToSave, open, setOpen, isFormValidated, titleError, versionError, idServiceError, textError }) {

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false)
    handleClearAllFields()
    navigate(`/dashboard/messages/messages-list`)
  };

  const handleCancel = () => {
    setOpen(false)
  };

  // Vérifier si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClose();
    }
  };

  return (
    isFormValidated ? (
      <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
        <DialogTitle>Confirmation d'ajout de message</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Message ajouté correctement </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    ) : (
      <div>
        <Dialog open={open} onClose={handleCancel}>
          <DialogTitle>Erreur dans le formulaire</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Un ou plusieurs champs du formulaire sont manquants ou érronés.
              Veuillez vérifier vos données ! <br />
              {titleError && <span> - {titleError}<br /></span>}
              {versionError && <span> - {versionError}<br /></span>}
              {idServiceError && <span> - {idServiceError}<br /></span>}
              {textError && <span> - {textError}<br /></span>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="error">Retour au Formulaire</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  )
}
