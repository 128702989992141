import React, { useEffect, useState } from "react";
import "./MuListeUtilisateurs.scss";
import GridTable from "../data-grid/GridTable";
import SearchBar from "../../../../components/search-bar/SearchBar";
import { getMunicipalityUsers } from "../httpQuerys";


export default function MuListeUtilisateurs({ allowedManagement }) {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [mustReload, setMustReload] = useState();

  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

  useEffect(() => {
    getMunicipalityUsers(currentCity.cityCode, currentUserInfo.idGroup).then((res) => {
      const filteredData = res.filter(item => item.fullName !== 'GOnet Version Public');
      setData(filteredData);
      setMustReload(false)
    })
  }, [currentCity.cityCode, currentUserInfo.idGroup, mustReload]);

  // search bar fonction
  const tabKeys = ['fullName', 'genre', 'email', 'telephone', 'ext', 'title', 'department', 'Description'];
  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    data &&
    currentCity && (
      <div className="MuListeUtilisateurs">
        <SearchBar query={query} setQuery={setQuery} />
        <GridTable data={showList(data)} setMustReload={setMustReload} allowedManagement={allowedManagement} />
      </div>
    )
  );
}
