import dataService from "../../../httpService";

// Fonction pour obtenir les municipalités de l'utilisateur
  const getUserMunicipalitesData = async (gde, currentUserInfo) => {
  try {
    const response = await dataService.get('/user/municipalities',{ params: { gde, currentUserInfo } });

    return response.data;
  } catch (error) {
    throw error;
  }
}
// Exporter les fonctions
export { getUserMunicipalitesData };