import React, { useMemo } from 'react';
import {  useLocation, useParams } from "react-router";
import './MuEditMessage.scss'
import { Link } from "react-router-dom";
import MuHeader from '../../../../components/mu-header/MuHeader'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PopUpForm from '../popupForm/PopUpForm';


export default function MuEditMessage() {
    const { cityCode } = useParams();
    const location= useLocation();

    const messageToEdit = useMemo(()=>location.state || {}, [location.state])

    // console.log(messageToEdit)

    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));

    return (
        <div className='MuEditMessage'>
            <MuHeader currentCity={currentCity} />

            <div className="topPage">
                <div className="topTitle">
                    <MarkUnreadChatAltIcon />
                    <div>Edition message contextuel {currentCity.description}</div>
                    <span><em>{currentCity.cityName}</em></span>
                </div>
                <div className="topNav">
                    <Link className='topNav-close' to={`/dashboard/municipalites/${cityCode}/mu-messages/mu-messages-list`} style={{ textDecoration: "none", cursor: 'pointer' }}>
                        <CloseRoundedIcon />
                    </Link>
                </div>
            </div>

            <div className='MuEditMessage-Panel'>
                <PopUpForm messageToEdit={messageToEdit} action="Update" />
            </div>

        </div>
    );
}
