import DOMPurify from 'dompurify';


export const validatePassword = (password, setPasswordError, setPasswordStrength) => {
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    const passwordRegex = /^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/;
    const sanitizedPassword = DOMPurify.sanitize(password);
    const isPasswordValid = passwordRegex.test(sanitizedPassword);

    if (!sanitizedPassword.trim()) {
        setPasswordError("Mot de passe obligatoire.");
        // setPasswordStrength('Faible');
        return false;
    }

    // Vérification de la présence de caractères spéciaux
    const specialCharRegex = /[^A-Za-z\d]/;
    if (specialCharRegex.test(sanitizedPassword)) {
        setPasswordError("Les caractères spéciaux ne sont pas acceptés !");
        setPasswordStrength('Faible');
        return false;
    }

    if (!isPasswordValid) {
        if (sanitizedPassword.length < 5) {
            setPasswordError("Mot de passe Faible.");
            setPasswordStrength('Faible');
            return true;
        }
        else {
            // const passwordErrorMessage = "Mot de passe Moyen. Au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre, au moins un caractère spécial parmi @$!%*?&#, et une longueur minimale de 8 caractères.";
            const passwordErrorMessage = "Mot de passe Moyen. Au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre et une longueur minimale de 8 caractères.";
            setPasswordError(passwordErrorMessage);
            setPasswordStrength('Moyen');
            return true;
        }
    }

    // Validation réussie
    setPasswordError('Mot de passe Élevé');
    setPasswordStrength('Eleve');
    return true;
};


const Utils = {
    validatePassword
}
export default Utils;