import dataService from "../../../httpService";

// Fonction pour obtenir tous les rôles possible de les sélectionner
async function getRoles() {
    try {
        const response = await dataService.get('/user/roles');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les titres possible de les sélectionner
async function getTitles() {
    try {
        const response = await dataService.get('/user/titles');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les départements possible de les sélectionner
async function getDepratements() {

    try {
        const response = await dataService.get('/user/departements');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste de tous les profils
async function getProfileList(idGroup) {
    try {
        const response = await dataService.get('/user/profile-list', {
            params: {
                idGroup
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste de tous les profils
async function getServicesProfile(cityCode, idGroup, nameProfile) {
    try {
        const response = await dataService.get('/user/services-profile-list', {
            params: {
                cityCode, idGroup, nameProfile
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

//! Fonction pour ajouter un nouvel utilisateur à une municipalité
async function addNewMunicipalityUser(userToAdd) {
    try {
        const response = await dataService.post('/user/add-new-municipality-user', userToAdd);
        return response.data;
    } catch (error) {
        throw error;
    }
}

//! Fonction pour obtenir les données de l'utilisateur à éditer
async function getUserToEdit(idUser, idGroup, cityCode) {
    try {
        const response = await dataService.get('/user/municipalitie/users/user/to-edit', {
            params: {
                idUser,
                idGroup,
                cityCode,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

//! Fonction pour mettre à jour les infos et les services d'un utilisateur d'une municipalité
async function updateMuUserInfoAndServies(updatedUser) {
    try {
        const response = await dataService.put('/user/update-municipality-user-services', {
            updatedUser: updatedUser,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


//! Fonction pour ajouter une municipalité et ses services à un utilisateur existant
async function addMunicipalityToUser(updatedUser) {
    try {
        const response = await dataService.post('/user/add-municipality-to-user', {
            updatedUser: updatedUser,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


//! Fonction pour supprimer un utilisateur à une municipalité
const deleteSelectedUser = async (row) => {
    try {
        const response = await dataService.delete('/user/delete-selected-municipality-user', {
            params: {
                idUser: row.idUser,
                cityCode: row.cityCode,
                idGroup: row.idGroup
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier l'unicité de l'identifiant userNAme de l'utilisateur à ajouter
async function checkUserNameUniqueness(userName) {
    try {
        const response = await dataService.get('/user/all-users/new-user/check-user-name', {
            params: { idUser: userName }
        });
        // console.log(response.data[0].duplicatedIdUser)
        return response.data[0].duplicatedIdUser;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier le mot de passe de l'utilisateur principal
async function checkUserPassword(password) {
    try {
        const response = await dataService.get('/user/check-password', {
            params: {
                password
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier le mot de passe de l'utilisateur principal
async function sendEmailToNewUser(userToAdd) {
    try {
        const response = await dataService.post('/user/send-email-new-user', userToAdd);
        return response.data;
    } catch (error) {
        throw error;
    }
}


// Fonction pour obtenir les messages d'une municipalité
async function getMunicipalityUsers(cityCode, idGroup) {
    try {
        const response = await dataService.get('/user/municipalitie/users', {
            params: {
                cityCode: cityCode,
                idGroup: idGroup
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

//! Fonction pour obtenir tous les utilisateurs
async function getAllUsersData() {
    try {
        const response = await dataService.get('/user/all-users');
        return response.data;
    } catch (error) {
        throw error;
    }
}

//! Fonction pour récuprer les utilisateur qu'on peut leur attribuer un nouveau service
async function getUsersToAddService(cityCode, idGroup) {

    try {
        const response = await dataService.get('/user/users-not-in-municipality', {
            params: {
                cityCode, idGroup
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Exporter les fonctions
export {
    addNewMunicipalityUser,
    checkUserNameUniqueness,
    checkUserPassword,
    sendEmailToNewUser,

    getUserToEdit,
    updateMuUserInfoAndServies,

    deleteSelectedUser,
    getRoles,
    getTitles,
    getDepratements,
    getProfileList,
    getServicesProfile,

    getMunicipalityUsers,
    getAllUsersData,
    getUsersToAddService,
    addMunicipalityToUser
};