import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import "./MuMiseEnLigneAddNew.scss"
// import {
//     addNewMiseEnLigne,
//     sendFilesMiseEnLigne,
// } from '../httpQuerys';
import {
    // validateField,
    validateAlphanumericField,
} from '../FunctionsUtils';
import {
    Paper,
    Button,
} from '@mui/material';

import DialogConfirm from '../DialogConfirm';

import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import MiseEnLigneAddNewForm from './MiseEnLigne-AddNew-Form/MiseEnLigneAddNewForm';

export default function MuMiseEnLigneAddNew({ allowedManagement }) {

    const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

    const miseEnLigneFormRef = useRef(null);
    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    const { cityCode } = currentCity
    const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    const [formDataMEL, setFormDataMEL] = useState({
        cityCode: cityCode,
        idDataTypeMaj: '',
        dateMaj: toDay,
        typeMaj: '',
        contact: currentUser.idUser,
        demandeur: currentUser.idUser,
        noteDemandeur: '',
        fichiers: [],
        filesName: [],
        PDForLink: '',
        lienWeb: '',
        producteur: '',
        dateProduction: null,
        idSubDataTypeMaj: null,
        zonageReglement: '',
        dateZonageEnVigueur: null,
        profile: '',
        services: [],
        messageMetaData: '',
        numeroReglement: '',
        zonageGuideInterpretation: '',
        zonageCoucheSource: '',
        zonageChampZone: '',

    });

    const [formErrors, setFormErrors] = useState({
        idDataTypeMaj: '',
        typeMaj: '',
        noteDemandeur: '',
        fichiers: '',
        PDForLink: '',
        lienWeb: '',
        producteur: '',
        dateProduction: '',
        idSubDataTypeMaj: '',
        zonageReglement: '',
        dateZonageEnVigueur: '',
        profile: '',
        services: '',
        messageMetaData: '',
        numeroReglement: '',
        zonageGuideInterpretation: '',
        zonageCoucheSource: '',
        zonageChampZone: '',
    });

    //! handle the  error
    // const handleErrorChange = (field, errorMessage) => {
    //     setFormErrors({
    //         ...formErrors,
    //         [field]: errorMessage
    //     });
    // };

    //! Pour définir une erreur sur un champ spécifique
    // handleErrorChange('thematique', 'La thématique est requise');

    // <form>
    //     <input
    //         type="text"
    //         name="thematique"
    //         value={formDataMEL.thematique}
    //         onChange={handleChange}
    //     />
    //     {formErrors.thematique && <span>{formErrors.thematique}</span>}

    //     <input
    //         type="text"
    //         name="contact"
    //         value={formDataMEL.contact}
    //         onChange={handleChange}
    //     />
    //     {formErrors.contact && <span>{formErrors.contact}</span>}

    //     {/* Répéter pour les autres champs */}
    // </form>


    // const [thematiqueError, setThematiqueError] = useState('');
    // const [contactError, setContactError] = useState('');
    // const [producteurError, setProducteurError] = useState('');
    // const [dateProductionError, setDateProductionError] = useState('');
    // const [noteError, setNoteError] = useState('');
    // const [fileError, setFileError] = useState('');
    // const [triennalError, setTriennalError] = useState('');
    // const [reglementEnVigueurError, setReglementEnVigueurError] = useState('');
    // const [donneesInclusesError, setDonneesInclusesError] = useState('');

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);

    // Validation d'un lien vers une page web
    function isValidURL(string) {
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // Protocole
            '((([a-zA-Z0-9$-_@.&+!*"(),])+\\.)+([a-zA-Z]{2,}))' + // Domaine
            '(\\/[a-zA-Z0-9$-_@.&+!*"(),]*)?' + // Chemin facultatif
            '(\\?.*)?$' // Query string facultatif
        );
        return !!urlPattern.test(string);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedFormData = {
            ...formDataMEL,
        };

        const updatedFormErrors = {
            ...formErrors,
        };

        // const typeMajArray = ['Autre', 'GOcadastre', 'Matrice', 'Rôle', 'Zonage', 'Orthophoto', 'Infrastructure', 'Croquis', 'Photos', 'Transactions immobilières', 'Taxes'];
        const typeMajArray = ['Matrice', 'Rôle', 'Zonage'];
        let validateForm = true;

        //!================================================================

        // Validation de la sélection d'un type de donnée
        if (formDataMEL.typeMaj === '') {
            updatedFormErrors.typeMaj = 'Veuillez sélectionner un type de donnée !';
            validateForm = false;
        }

        // Validation de la sélection d'un sous type de donnée
        if (
            (updatedFormData.typeMaj === 'Matrice') ||
            (updatedFormData.typeMaj === 'Zonage') ||
            (updatedFormData.typeMaj === 'Infrastructure')
        ) {
            if (updatedFormData.idSubDataTypeMaj === null) {
                updatedFormErrors.idSubDataTypeMaj = `Veuillez sélectionner un sous type pour la mise à jour ${updatedFormData.typeMaj}`;
                validateForm = false;
            }
        }

        //!================================================================

        // Validation de la sélection d'un ou de plusieurs fichiers
        if (typeMajArray.includes(updatedFormData.typeMaj) && updatedFormData.fichiers.length === 0 && !updatedFormData.lienWeb) {
            updatedFormErrors.fichiers = 'Veuillez sélectionner le ou les fichiers à déposer !';
            validateForm = false;
        }

        if (typeMajArray.includes(updatedFormData.typeMaj) && updatedFormData.fichiers.length > 0) {
            // Extraire les noms des fichiers
            const filesName = formDataMEL.fichiers.map(file => file.name);
            // Ajouter le tableau des noms au formDataMEL
            updatedFormData.filesName = filesName;

            // Créer un objet formData pour les fichiers à envoyer vers le serveur
            const formDataFilesToSend = new FormData();

            // Remplacer par les fichiers réels qu'on a en mémoire
            updatedFormData.filesName.forEach((fileName) => {
                const file = new File([], fileName); // Créer un fichier vide

                // Ajouter le fichier au FormData
                formDataFilesToSend.append('fichiers', file);
            });

            // Affichage pour vérifier le contenu
            // for (let [key, value] of formDataFilesToSend.entries()) {
            //     console.log(`${key}: ${value.name || value}`); // Affiche le nom du fichier ou la valeur
            // }
        }

        //!================================================================

        // Validation de 'Producteur et dateProduction'
        if ((updatedFormData.typeMaj === 'Autre') ||
            (updatedFormData.typeMaj === 'Matrice' && (updatedFormData.idSubDataTypeMaj === 2 || updatedFormData.idSubDataTypeMaj === 10)) ||
            (updatedFormData.typeMaj === 'Zonage') ||
            (updatedFormData.typeMaj === 'Orthophoto') ||
            (updatedFormData.typeMaj === 'Croquis') ||
            (updatedFormData.typeMaj === 'Photos') ||
            (updatedFormData.typeMaj === 'Transactions immobilières')
        ) {
            // Validation du champs 'Producteur'
            validateAlphanumericField(updatedFormData.producteur, 'Producteur', (errorMessage) => {
                updatedFormErrors.producteur = errorMessage;
                if (errorMessage) validateForm = false;
            });

            // Validation de la date 'dateProduction'
            if (dayjs(updatedFormData.dateProduction).isValid()) {
                // Vérifier si la date sélectionnée est aujourd'hui
                if (dayjs(updatedFormData.dateProduction).isSame(dayjs(), 'day')) {
                    updatedFormErrors.dateProduction = "La date d'aujourd'hui est sélectionnée par défaut";
                } else {
                    updatedFormErrors.dateProduction = ''; // Pas d'erreur si la date est valide et différente d'aujourd'hui
                }
            } else {
                updatedFormErrors.dateProduction = 'La date n\'est pas correcte';
                validateForm = false; // Formulaire invalide si la date n'est pas correcte
            }
        }

        // Validation du champs 'Numero de reglement'
        if (updatedFormData.typeMaj === 'Zonage') {
            validateAlphanumericField(updatedFormData.numeroReglement, 'Numero de reglement', (errorMessage) => {
                updatedFormErrors.numeroReglement = errorMessage;
                if (errorMessage) validateForm = false;
            });
        }

        // Validation de la date 'dateZonageEnVigueur'
        if (updatedFormData.typeMaj === 'Zonage') {
            if (dayjs(updatedFormData.dateZonageEnVigueur).isValid()) {
                // Vérifier si la date sélectionnée est aujourd'hui
                if (dayjs(updatedFormData.dateZonageEnVigueur).isSame(dayjs(), 'day')) {
                    updatedFormErrors.dateZonageEnVigueur = "La date d'aujourd'hui est sélectionnée par défaut";
                } else {
                    updatedFormErrors.dateZonageEnVigueur = ''; // Pas d'erreur si la date est valide et différente d'aujourd'hui
                }
            } else {
                updatedFormErrors.dateZonageEnVigueur = 'La date n\'est pas correcte';
                validateForm = false; // Formulaire invalide si la date n'est pas correcte
            }
        }

        // Validation du champs 'Couche source'
        if (updatedFormData.typeMaj === 'Zonage' && updatedFormData.idSubDataTypeMaj === 4) {
            validateAlphanumericField(updatedFormData.zonageCoucheSource, 'Couche source', (errorMessage) => {
                updatedFormErrors.zonageCoucheSource = errorMessage;
                if (errorMessage) validateForm = false;
            });
        }

        // Validation du champs 'Champ zone'
        if (updatedFormData.typeMaj === 'Zonage' && updatedFormData.idSubDataTypeMaj === 4) {
            validateAlphanumericField(updatedFormData.zonageChampZone, 'Champ zone', (errorMessage) => {
                updatedFormErrors.zonageChampZone = errorMessage;
                if (errorMessage) validateForm = false;
            });
        }

        // Validation de Fichier .PDF ou un Lien vers une page Web
        if (
            (updatedFormData.typeMaj === 'Zonage') &&
            (updatedFormData.idSubDataTypeMaj === 6 || updatedFormData.idSubDataTypeMaj === 8) &&
            (updatedFormData.PDForLink === '')
        ) {
            updatedFormErrors.PDForLink = 'Veuillez selectionner Fichier .PDF ou un Lien vers une page Web !'
            validateForm = false; // Formulaire invalide si pas de sélection
        }

        // Validation du lien Web
        if (
            (updatedFormData.typeMaj === 'Zonage') &&
            (updatedFormData.idSubDataTypeMaj === 6 || updatedFormData.idSubDataTypeMaj === 8) &&
            (updatedFormData.PDForLink === 'Link') &&
            (!isValidURL(updatedFormData.lienWeb))
        ) {
            updatedFormErrors.zonageReglement = 'Veuillez entrer un lien valide vers une page web !'
            updatedFormErrors.zonageGuideInterpretation = 'Veuillez entrer un lien valide vers une page web !'
            validateForm = false; // Formulaire invalide si pas de sélection
        }


        // Mise à jour des champs directement pour le lien local au fichier PDF
        if (updatedFormData.typeMaj === 'Zonage') {
            if (updatedFormData.idSubDataTypeMaj === 6 && updatedFormData.zonageReglement === '') {
                updatedFormData.zonageReglement = updatedFormData.filesName[0];
            } else if (updatedFormData.idSubDataTypeMaj === 8 && updatedFormData.zonageGuideInterpretation === '') {
                updatedFormData.zonageGuideInterpretation = updatedFormData.filesName[0];
            }
        }

        // Mise à jour des champs directement pour le lien local au fichier PDF
        if (updatedFormData.typeMaj === 'Autre') {
            if (updatedFormData.services.length === 0) {
                updatedFormErrors.profile = 'test';
                validateForm = false;
            } else {
                updatedFormErrors.profile = ''
            }
        }



        // console.log('submit ==>> ', updatedFormData)
        // console.log('Errors ==>> ', updatedFormErrors)

        setFormDataMEL(updatedFormData);
        setFormErrors(updatedFormErrors);

        if (validateForm) {
            console.log('Soumission du Formulaire');
            setIsFormValidated(true);
            // permet d'afficher la boite de dialogue à la soumission du formulaire
            // setOpen(true);
        }
        else {
            console.log('Un des champs est manquant ou mal saisi');
            setIsFormValidated(false);
        }

        // permet d'afficher la boite de dialogue à la soumission du formulaire
        setOpen(true);
    };

    return (
        <div className='MiseEnLigneAddNew'>

            {open && (
                <DialogConfirm
                    formDataMEL={formDataMEL}
                    open={open}
                    setOpen={setOpen}
                    isFormValidated={isFormValidated}
                />
            )}

            <Paper elevation={5} className='MiseEnLigneAddNew-paper' sx={{ height: 600, overflowY: 'auto' }}>
                <form ref={miseEnLigneFormRef} className='MiseEnLigneAddNew-Form' onSubmit={handleSubmit}>

                    <MiseEnLigneAddNewForm
                        formDataMEL={formDataMEL}
                        setFormDataMEL={setFormDataMEL}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        allowedManagement={allowedManagement}
                    />

                    <hr />

                    <div className='footerMiseEnLigneAddNew'>
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne`} style={{ textDecoration: "none" }}>
                            <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                                Annuler
                            </Button>
                        </Link>

                        <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                            Enregistrer
                        </Button>

                    </div>

                </form>
            </Paper>

        </div>
    )
}
