import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './EditOneMessageOfAll.scss';
import {
    getServices,
    updateMuMessage,
} from '../httpQuerys';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Grid,
    Input,
    InputLabel,
    InputAdornment,
    Typography,
    Stack,
    FormControl,
    Paper,
    Button,
    FormControlLabel,
    Checkbox,
    Slider,
    IconButton,
    Autocomplete,
    TextField,
} from '@mui/material';

import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditorWysiwyg from '../Editor-Wysiwyg/EditorWysiwyg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import DialogConfirm from './DialogConfirm';



export default function EditOneMessageOfAll() {
    // const { idCityMessage } = useParams();
    const location = useLocation();
    const messageToEdit = useMemo(() => location.state || {}, [location.state]);

    const navigate = useNavigate();
    useEffect(() => {
        // Vérifier si messageToEdit n'est pas un objet vide ou null
        if (Object.keys(messageToEdit).length === 0 && messageToEdit.constructor === Object) {
            // Naviguer vers la page /dashboard/messages/messages-list
            navigate('/dashboard/messages/messages-list');
        }
    }, [messageToEdit, navigate]);

    //!----------------------------------------------------------------

    const editOneMessageOfAllFormRef = useRef(null);
    const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

    const [messageToSave, setMessageToSave] = useState();

    const [title, setTitle] = useState(messageToEdit.title);
    const [titleError, setTitleError] = useState('');

    const [startDate, setStartDate] = useState(dayjs(messageToEdit.dateStart).add(1, 'day'));
    const [startDateError, setStartDateError] = useState('');
    const [endDate, setEndDate] = useState(dayjs(messageToEdit.dateEnd).add(1, 'day'));
    const [endDateError, setEndDateError] = useState('');

    const [privateVersion, setPrivateVersion] = useState(messageToEdit.privateVersion);
    const [publicVersion, setPublicVersion] = useState(messageToEdit.publicVersion);
    const [versionError, setVersionError] = useState('');

    const [autoClose, setAutoClose] = useState(messageToEdit.autoClose);
    const [userClose, setUserClose] = useState(messageToEdit.userClose);
    const [displayTime, setDisplayTime] = useState(messageToEdit.displayTime);
    const [orderBy, setOrderBy] = useState(messageToEdit.orderBy);

    const [services, setServices] = useState([]);
    const [idService, setIdService] = useState(messageToEdit.idService);
    const [idServiceError, setIdServiceError] = useState('');

    const [typeText, setTypeText] = useState((messageToEdit.idtypeMessage === 4 || messageToEdit.idtypeMessage === 5) ? 'print' : 'html')
    const [frMessage, setFrMessage] = useState(messageToEdit.text_fr);
    const [enMessage, setEnMessage] = useState(messageToEdit.text_en);
    const [textError, setTextError] = useState('');

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);

    //!----------------------------------------------------------------

    // Si le type de message choisi est = 1 ou 3 On attribue la valeur de service
    useEffect(() => {
        const fetchServices = async () => {
            if (messageToEdit.idtypeMessage === 1 || messageToEdit.idtypeMessage === 3) {
                let foundService = messageToEdit.idService;
                let filteredServices = [];

                try {
                    const data = await getServices(messageToEdit.cityCode);

                    if ((!publicVersion && !privateVersion) || (publicVersion && privateVersion)) {
                        filteredServices = [];
                        foundService = null;
                    } else if (publicVersion && !privateVersion) {
                        filteredServices = data.filter(service => service.servicePublic === true);
                        foundService = filteredServices.length > 0 ? filteredServices[0].idService : null;
                    } else if (!publicVersion && privateVersion) {
                        filteredServices = data.filter(service => service.servicePublic === false);
                    }

                    setServices(filteredServices);
                    setIdService(foundService);
                } catch (error) {
                    console.error('Error fetching services:', error);
                    setServices([]);
                    setIdService(null);
                }
            }
        };

        fetchServices();
    }, [messageToEdit?.cityCode, messageToEdit.idService, messageToEdit.idtypeMessage, privateVersion, publicVersion]);

    // données des Options de la liste déroulante des services
    const servicesDefaultProps = {
        options: services,
        getOptionLabel: (option) => option.aliasName ? `${option.idService} - ${option.aliasName}` : ''
    };

    // ---------------------------------------------------------------
    // Configuration de la locale pour Day.js
    dayjs.locale('fr-ca');
    // const today = dayjs().isValid() ? dayjs().locale('fr-ca').format('YYYY-MM-DD HH:mm:ss') : null;
    // ---------------------------------------------------------------

    const capitalizeTheFirstLetter = (str) => {
        //seulement la premiere lettre en majuscule
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleStartDateChange = (newValue) => {
        // Vérifier si la date de debut est inférieure à la date d'aujourd'hui
        if (dayjs(newValue).isBefore(dayjs().subtract(1, 'days'))) {
            setStartDateError('La date de début doit être supérieure ou égale à la date d\'aujourd\'hui.');
        } else {
            setStartDate(newValue);
            setEndDate(dayjs(newValue).add(30, 'days'));
            setStartDateError('');
            setEndDateError('');
        }
    };

    const handleEndDateChange = (newValue) => {
        // Vérifier si la date de fin est inférieure à la date de début
        if (dayjs(newValue).isBefore(startDate)) {
            setEndDateError('La date de fin doit être supérieure à la date de début.');
            // Ajouter automatiquement 30 jours à la date de début
            setEndDate(dayjs(startDate).add(30, 'days'));
        } else {
            setEndDate(newValue);
            setEndDateError('');
        }
    };

    // Effacer le contenu detous les champs
    const handleClearAllFields = () => {
        setTitle('');
        setTitleError('');
        setStartDate(dayjs());
        setStartDateError('');
        setEndDate(dayjs().add(1, 'month'));
        setEndDateError('');
        setPrivateVersion(false);
        setPublicVersion(false);
        setVersionError('');
        setIdService(null);
        setIdServiceError('');
        setAutoClose(true);
        setUserClose(false);
        setDisplayTime(30000);
        setOrderBy(1);
        setFrMessage('');
        setEnMessage('');
        setTextError('');
    };

    // Réinitialiser tous les états
    const handleReset = () => {
        setTitle(messageToEdit.title || '');
        setTitleError('');
        setStartDate(dayjs(messageToEdit.dateStart).add(1, 'day'));
        setStartDateError('');
        setEndDate(dayjs(messageToEdit.dateEnd).add(1, 'day'));
        setEndDateError('');
        setPrivateVersion(messageToEdit.privateVersion || false);
        setPublicVersion(messageToEdit.publicVersion || false);
        setVersionError('');
        setIdService(messageToEdit.idService)
        setIdServiceError('');
        setAutoClose(messageToEdit.autoClose || true);
        setUserClose(messageToEdit.userClose || false);
        setDisplayTime(messageToEdit.displayTime || 30000);
        setOrderBy(messageToEdit.orderBy || 1);
        setTypeText((messageToEdit.idtypeMessage === 4 || messageToEdit.idtypeMessage === 5) ? 'print' : 'html');
        setFrMessage(messageToEdit.text_fr || '');
        setEnMessage(messageToEdit.text_en || '');
        setTextError('');
    };

    //!----------------------------------------------------------------
    // Définir le format de date souhaité exp : 2024-07-24 00:00:00.000
    const formatDate = (date) => {
        return date.hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm:ss.SSS');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (title === '')
            setTitleError('Un titre est obligatoire.')
        else
            setTitleError('')

        if (!publicVersion && !privateVersion)
            setVersionError('Au moins l\'une des deux versions doit être sélectionnée.')
        else
            setVersionError('')

        if (!publicVersion && privateVersion && !idService)
            setIdServiceError('Un service doit être sélectionner pour la version privée.')
        else
            setIdServiceError('')

        if ((frMessage === '' && enMessage === '')) {
            setTextError("Aucun message n'a été saisi. Cela implique qu'il n'y aura pas de message à afficher.");
        } else {
            setTextError('');
        }

        const isFormValid =
            (title !== '' && !titleError) &&
            ((publicVersion === true || privateVersion === true) && !versionError) &&
            !(!publicVersion && privateVersion && !idService) &&
            (startDate !== '' && !startDateError) &&
            (endDate !== '' && !endDateError) &&
            ((frMessage !== '' || enMessage !== '') && !textError);

        // Copier les états actuels dans de nouvelles variables
        let newFrMessage = frMessage;
        let newEnMessage = enMessage;

        // Si le type de texte est 'print' ou 'html', modifier les messages en conséquence
        if (typeText === 'print') {
            newFrMessage = frMessage
                ? frMessage.replace(/<p><br><\/p>/g, '\\n').replace(/<\/\w+>/g, '\\n').replace(/<[^>]*>/g, '').replace(/'/g, '’')
                : '';
            newEnMessage = enMessage
                ? enMessage.replace(/<p><br><\/p>/g, '\\n').replace(/<\/\w+>/g, '\\n').replace(/<[^>]*>/g, '').replace(/'/g, '’')
                : '';
        } else if (typeText === 'html') {
            newFrMessage = frMessage ? frMessage.replace(/'/g, '’') : '';
            newEnMessage = enMessage ? enMessage.replace(/'/g, '’') : '';

            if (messageToEdit.idtypeMessage === 1) {
                newFrMessage = `
                    <div class="layout-1">
                      <br />
                      <div class="text" style="text-align: center">
                        <p>${newFrMessage}</p>
                      </div>
                    </div>
                    <br />`;
                newEnMessage = `
                    <div class="layout-1">
                      <br />
                      <div class="text" style="text-align: center">
                        <p>${newEnMessage}</p>
                      </div>
                    </div>
                    <br />`;
            }
        }

        const messageToSave = {
            idCityMessage: messageToEdit.idCityMessage,
            dateStart: (messageToEdit.idtypeMessage === 1) || (messageToEdit.idtypeMessage === 3) ? formatDate(startDate) : null,
            dateEnd: (messageToEdit.idtypeMessage === 1) || (messageToEdit.idtypeMessage === 3) ? formatDate(endDate) : null,
            autoClose: autoClose || true,
            idtypeMessage: messageToEdit.idtypeMessage,
            privateVersion: privateVersion,
            publicVersion: publicVersion,
            userClose: userClose || false,
            orderBy: orderBy,
            frequency: 0,
            blocking: 0,
            nameMessage: title,
            idService: idService ?? null,
            isAzimut: 0,
            author: currentUserInfo.idUser,
            displayTime: displayTime || null,
            idMessage: messageToEdit.idMessage,
            title: title,
            text_fr: newFrMessage,
            text_en: newEnMessage,
        }

        if (isFormValid) {
            let result = undefined
            // console.log('mise à jour')
            result = await updateMuMessage(messageToSave);

            if (result) {
                console.log('Soumission du Formulaire de la mise à jour d\'un message pour la municipalité');
            }

            setIsFormValidated(true);
            setMessageToSave(messageToSave);
            // permet d'afficher la boite de dialogue à la soumission du formulaire
            setOpen(true);
        } else {
            console.log('Un des champs est manquant ou mal saisi');
            setIsFormValidated(false);
            setOpen(true);
        }
    }

    return (
        // Object.keys(messageToEdit).length !== 0 ?
        <div className="editOneMessageOfAll">
            {open && (
                <DialogConfirm
                    handleClearAllFields={handleClearAllFields}
                    messageToSave={messageToSave}
                    open={open}
                    setOpen={setOpen}
                    isFormValidated={isFormValidated}
                    titleError={titleError}
                    versionError={versionError}
                    idServiceError={idServiceError}
                    textError={textError}
                />
            )}

            <div className="topPage">
                <div className="topTitle">
                    <RateReviewOutlinedIcon />
                    <div>Edition du message <span>{messageToEdit.typedescription}</span> pour </div>
                    <span><em>{messageToEdit.cityName}</em></span>
                </div>
                <div className="topNav">
                    <Link className='topNav-close' to={`/dashboard/messages/messages-list`} style={{ textDecoration: "none", cursor: 'pointer' }}>
                        <CloseRoundedIcon />
                    </Link>
                    <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                        Enregistrer
                    </Button>
                </div>
            </div>

            <Paper elevation={5} className='editOneMessageOfAll-paper' sx={{ height: 600, overflowY: 'auto' }}>
                <form ref={editOneMessageOfAllFormRef} className='editOneMessageOfAll-form' onSubmit={handleSubmit}>
                    <Grid container className='gridContainer' spacing={2} m='0' >

                        <Grid item className='gridItem' xs={12} md={6} lg={6}>
                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                <InputLabel htmlFor="fullname">Auteur</InputLabel>
                                <Input
                                    id="fullname"
                                    name="fullname"
                                    autoComplete="fullname"
                                    disabled
                                    value={messageToEdit.fullName || ''}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
                                <InputLabel htmlFor="titre">Titre du message</InputLabel>
                                <Input
                                    id="titre"
                                    name="titre"
                                    autoComplete="titre"
                                    required

                                    value={title || ''}

                                    onChange={(e) => {
                                        const newValue = capitalizeTheFirstLetter(e.target.value);
                                        setTitle(newValue)
                                        setTitleError('')
                                    }}

                                    endAdornment={
                                        title && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setTitle('')}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                                {titleError && <Typography variant="caption" color="error">{titleError}</Typography>}
                            </FormControl>
                        </Grid>

                        {(messageToEdit.idtypeMessage === 3 || messageToEdit.idtypeMessage === 1) &&
                            <>
                                <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                                        <DatePicker
                                            label="Date début d'affichage"
                                            format="YYYY/MM/DD"
                                            openTo="day"
                                            views={["year", "month", "day"]}
                                            slotProps={{
                                                textField: {
                                                    variant: "filled",
                                                    size: "small",
                                                    style: { width: '100%', height: '100%' }
                                                }
                                            }}

                                            value={startDate || null}
                                            onChange={(newValue) => {
                                                handleStartDateChange(newValue)
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {startDateError && <p style={{ color: 'red' }}>{startDateError}</p>}
                                </Grid>

                                <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                                        <DatePicker
                                            label="Date fin d'affichage"
                                            format="YYYY/MM/DD"
                                            openTo="day"
                                            views={["year", "month", "day"]}
                                            slotProps={{
                                                textField: {
                                                    variant: "filled",
                                                    size: "small",
                                                    style: { width: '100%', height: '100%' }
                                                }
                                            }}

                                            value={endDate || null}
                                            onChange={(newValue) => {
                                                handleEndDateChange(newValue)
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {endDateError && <p style={{ color: 'red' }}>{endDateError}</p>}
                                </Grid>
                            </>
                        }

                        <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>
                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                <FormControlLabel className='gridItem-formControlLabel left'
                                    control={
                                        <Checkbox
                                            className='checkbox-version'
                                            id="publicVersion"
                                            name="publicVersion"

                                            checked={publicVersion || false}
                                            onChange={(e) => {
                                                setPublicVersion(e.target.checked)
                                                setVersionError('');
                                                setIdService(null);
                                                setIdServiceError('');
                                            }}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'publicVersion checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className='version-label'>
                                            Version publique
                                        </div>
                                    }
                                    labelPlacement="end"
                                />
                            </FormControl>

                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                <FormControlLabel className='gridItem-formControlLabel right'
                                    control={
                                        <Checkbox
                                            className='checkbox-version'
                                            id="privateVersion"
                                            name="privateVersion"

                                            checked={privateVersion || false}
                                            onChange={(e) => {
                                                setPrivateVersion(e.target.checked);
                                                setVersionError('');
                                                setIdService(null);
                                                setIdServiceError('')
                                            }}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'contact checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className='version-label'>
                                            {/* <PermContactCalendarIcon
                                                sx={{
                                                    // color: contact ? 'green' : (municipality ? 'action.active' : 'lightgray'), // Use light gray if disabled

                                                    // color: contact ? 'green' : 'action.active',
                                                    marginRight: '0.5rem'
                                                }}
                                                /> */}
                                            Version privée
                                        </div>
                                    }
                                    labelPlacement="end"
                                />
                            </FormControl>
                        </Grid>

                        {(!publicVersion
                            && privateVersion
                            && (messageToEdit.idtypeMessage === 3 || messageToEdit.idtypeMessage === 1)) &&

                            <Grid item className='gridItem' xs={12} md={6} lg={6} >
                                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                    <Stack spacing={1} >
                                        <Autocomplete
                                            {...servicesDefaultProps}
                                            id="services"
                                            name="services"

                                            isOptionEqualToValue={(option, value) => option.idService === value.idService}
                                            // Trouver l'objet complet ou retourner null si non trouvé
                                            value={services.find(service => service.idService === idService) || null}
                                            onChange={(event, newValue) => {
                                                // Si newValue n'est pas null avant d'essayer d'accéder à sa propriété
                                                if (newValue) {
                                                    setIdService(newValue.idService);
                                                    setIdServiceError('');
                                                } else {
                                                    // Gérer le cas où aucune option n'est sélectionnée ou toute autre valeur par défaut appropriée
                                                    setIdService(null);
                                                    setIdServiceError('');
                                                }
                                            }}

                                            clearOnEscape
                                            renderInput={(params) => <TextField {...params} label="Services" variant="standard" name="services" />}
                                        />
                                    </Stack>
                                </FormControl>
                            </Grid>
                        }

                        {messageToEdit.idtypeMessage === 1 &&
                            <>
                                <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>

                                    <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                        <FormControlLabel
                                            className='gridItem-formControlLabel left'
                                            control={
                                                <Checkbox
                                                    className='checkbox-version'
                                                    id="autoClose"
                                                    name="autoClose"

                                                    checked={autoClose || false}
                                                    onChange={(e) =>
                                                        setAutoClose(e.target.checked)
                                                    }

                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'autoClose checkbox' }}
                                                />
                                            }
                                            label={
                                                <div className='version-label'>
                                                    Auto-fermeture
                                                </div>
                                            }
                                            labelPlacement="end"
                                        />
                                    </FormControl>

                                    <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                        <FormControlLabel
                                            className='gridItem-formControlLabel right'
                                            control={
                                                <Checkbox
                                                    className='checkbox-version'
                                                    id="userClose"
                                                    name="userClose"

                                                    checked={userClose || false}
                                                    onChange={(e) =>
                                                        setUserClose(e.target.checked)
                                                    }

                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'userClose checkbox' }}
                                                />
                                            }
                                            label={
                                                <div className='version-label'>
                                                    Fermeture par l'utilisateur
                                                </div>
                                            }
                                            labelPlacement="end"
                                        />
                                    </FormControl>

                                </Grid>

                                <Grid item className='gridItem' xs={12} md={6} lg={6} >
                                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                        <div className='sliderLabel'>
                                            <Typography gutterBottom>Temps d'affichage &nbsp; </Typography>
                                            <div className='sliderInput'>

                                                <Input
                                                    size="small"

                                                    value={displayTime / 1000 || 0}
                                                    onChange={(e) => {
                                                        setDisplayTime(e.target.value === '' ? 0 : Number(e.target.value) * 1000);
                                                    }}

                                                    inputProps={{
                                                        step: 1,
                                                        min: 0,
                                                        max: 60,
                                                        type: 'number',
                                                    }}
                                                />
                                                <Typography gutterBottom>
                                                    &nbsp; <span style={{ fontStyle: 'italic', fontSize: 'small' }}>sec</span>
                                                </Typography>
                                            </div>
                                        </div>

                                        <Slider
                                            aria-label="Temps"
                                            defaultValue={displayTime / 1000 || 0}
                                            valueLabelDisplay="auto"
                                            valuelabelplacement="bottom"

                                            value={displayTime / 1000 || 0}
                                            onChange={(e) => {
                                                setDisplayTime(e.target.value === '' ? 0 : Number(e.target.value) * 1000);
                                            }}

                                            step={1}
                                            min={0}
                                            max={60}
                                            aria-labelledby="input-slider"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item className='gridItem' xs={12} md={6} lg={6}>
                                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
                                        <div className='orderBy'>
                                            <Typography gutterBottom>Ordre d'affichage &nbsp; </Typography>
                                            <Input
                                                className='orderBy-control'
                                                id="orderBy"
                                                name="orderBy"

                                                size="small"

                                                value={orderBy || 0}
                                                onChange={(e) => {
                                                    setOrderBy(e.target.value === '' ? 0 : Number(e.target.value));
                                                }}

                                                inputProps={{
                                                    step: 1,
                                                    min: 0,
                                                    max: 100,
                                                    type: 'number',
                                                }}
                                            />
                                        </div>
                                    </FormControl>
                                </Grid>
                            </>
                        }

                        <hr />

                        <Grid item className='gridError' xs={12} md={12} lg={12} >
                            {versionError && <Typography variant="caption" color="error">{versionError}</Typography>}
                        </Grid>

                        <Grid item className='gridError' xs={12} md={12} lg={12} >
                            {idServiceError && <Typography variant="caption" color="error">{idServiceError}</Typography>}
                        </Grid>

                        <Grid item className='gridError' xs={12} md={12} lg={12} >
                            {textError && <Typography variant="caption" color="error">{textError}</Typography>}
                        </Grid>

                        <hr />

                        <EditorWysiwyg language={'français'} message={frMessage} setMessage={setFrMessage} setMessageError={setTextError} />

                        <EditorWysiwyg language={'anglais'} message={enMessage} setMessage={setEnMessage} setMessageError={setTextError} />

                        <hr />

                        <div className='footer-Edit-OneMessageOfAll'>
                            <Link to={`/dashboard/messages/messages-list`} style={{ textDecoration: "none" }}>
                                <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                                    Annuler
                                </Button>
                            </Link>

                            <Button
                                className='btnSubmit'
                                variant="contained"
                                sx={{ width: '200px', cursor: 'pointer' }}
                                // disabled={messageDetails === lastMessageValues}
                                onClick={handleReset}
                            >
                                Réinitialiser
                            </Button>

                            <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                                Enregistrer
                            </Button>
                        </div>
                    </Grid>
                </form>
            </Paper>
        </div>
    )
}




