import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import './PopUpForm.scss';
import {
    getServices,
    addMuMessage,
    updateMuMessage,
    getMessageTypeList,
} from '../httpQuerys';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Grid,
    Input,
    InputLabel,
    InputAdornment,
    Typography,
    Stack,
    FormControl,
    Paper,
    Button,
    FormControlLabel,
    Checkbox,
    IconButton,
    Autocomplete,
    TextField,
    Slider,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditorWysiwyg from './Editor-Wysiwyg/EditorWysiwyg';
import DialogConfirm from './DialogConfirm';

export default function PopUpForm({ messageToEdit, action }) {

    const PopUpMessagesFormRef = useRef(null);
    const navigate = useNavigate();

    const { cityCode } = useParams();
    // const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

    // Définir l'état initial
    const initialState = {
        cityCode: cityCode,
        idCityMessage: messageToEdit?.idCityMessage || null,
        idMessage: messageToEdit?.idMessage || null,
        idtypeMessage: messageToEdit?.idtypeMessage || null,
        author: currentUserInfo.idUser,
        fullName: currentUserInfo.fullName,
        title: messageToEdit?.title || '',
        nameMessage: messageToEdit?.nameMessage || '',
        dateStart: messageToEdit?.dateStart ? dayjs(messageToEdit.dateStart) : dayjs(),
        dateEnd: messageToEdit?.dateEnd ? dayjs(messageToEdit.dateEnd) : dayjs().add(1, 'month'),
        publicVersion: messageToEdit?.publicVersion || false,
        privateVersion: messageToEdit?.privateVersion || false,
        idService: messageToEdit?.idService || null,
        autoClose: messageToEdit?.autoClose || false,
        userClose: messageToEdit?.userClose || false,
        displayTime: messageToEdit?.displayTime || 30000,
        orderBy: messageToEdit?.orderBy || 1,
        typeText: messageToEdit?.idtypeMessage === 4 || messageToEdit?.idtypeMessage === 5 ? 'print' : 'html',
        text_fr: messageToEdit?.text_fr || '',
        text_en: messageToEdit?.text_en || '',
        blocking: 0,
        frequency: 1,
        isAzimut: 0,
    };

    // Définir l'état initial des erreurs
    const initialErrorsState = {
        title: '',
        dateStart: '',
        dateEnd: '',
        version: '',
        idService: '',
        text: '',
    };

    // Initialiser l'état de formState et de errorsState
    const [formState, setFormState] = useState(initialState);
    const [errorsState, setErrorsState] = useState(initialErrorsState);

    const [messageTypeList, setMessageTypeList] = useState([])
    const [services, setServices] = useState([]);
    const [messageType, setMessageType] = useState(null)

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);

    // // const [openAutoC, setOpenAutoC] = useState(false);
    // // const [loading, setLoading] = useState(true);
    // // const loading = openAutoC && municipalities.length === 0;

    // ---------------------------------------------------------------
    // Configuration de la locale pour Day.js
    dayjs.locale('fr-ca');
    // const today = dayjs().isValid() ? dayjs().locale('fr-ca').format('YYYY-MM-DD HH:mm:ss') : null;
    // ---------------------------------------------------------------


    //  Liste des types de message ( 1, 3 ) à récupérer de la BD une seule fois lors du chargement
    useEffect(() => {
        getMessageTypeList().then((data) => {
            setMessageTypeList(data.filter(item => [1, 3].includes(item.idtypeMessage)));
        });
    }, []);

    // données des Options de la liste déroulante pour les types de messages
    const messageTypeListDefaultProps = {
        options: messageTypeList,
        // getOptionLabel: (option) => `${option.idtypeMessage} - ${option.typedescription}`
        getOptionLabel: (option) => `${option.typedescription}`
    };


    // On va chercher le messageType qui correspond dans la liste de messages pour un update selon messageToEdit
    useEffect(() => {
        if (messageToEdit?.idtypeMessage && messageTypeList.length > 0) {
            const selectedMessageType = messageTypeList.find(
                (item) => item.idtypeMessage === messageToEdit.idtypeMessage
            );
            setMessageType(selectedMessageType || null);
        }
    }, [messageToEdit?.idtypeMessage, messageTypeList]);


    useEffect(() => {
        const fetchServices = async () => {
            if (messageType) {
                try {
                    const data = await getServices(cityCode);
                    setServices(data);
                } catch (error) {
                    console.error('Error fetching services:', error);
                    setServices([]);
                }
            }
        };

        fetchServices();
    }, [cityCode, messageType]);

    // données des Options de la liste déroulante des services
    const servicesDefaultProps = {
        options: services,
        // getOptionLabel: (option) => option.aliasName ? `${option.idService} - ${option.aliasName}` : ''
        getOptionLabel: (option) => option.aliasName ? `${option.aliasName}` : ''
    };

    const capitalizeTheFirstLetter = (str) => {
        //seulement la premiere lettre en majuscule
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleStartDateChange = (newValue) => {
        // Vérifier si la date de debut est inférieure à la date d'aujourd'hui
        if (dayjs(newValue).isBefore(dayjs().subtract(1, 'days'))) {
            setErrorsState(prevState => ({
                ...prevState,
                dateStart: 'La date de début doit être supérieure ou égale à la date d\'aujourd\'hui.',
            }));
        } else {
            setFormState(prevState => ({
                ...prevState,
                dateStart: newValue,
                dateEnd: dayjs(newValue).add(30, 'days'),
            }));
            setErrorsState(prevState => ({
                ...prevState,
                dateStart: '',
                dateEnd: '',
            }));
        }
    };

    const handleEndDateChange = (newValue) => {
        // Vérifier si la date de fin est inférieure à la date de début
        if (dayjs(newValue).isBefore(formState.dateStart)) {
            setErrorsState(prevState => ({
                ...prevState,
                dateEnd: 'La date de fin doit être supérieure à la date de début.',
            }));
            // Ajouter automatiquement 30 jours pour la date fin par rapport à la date de début
            setFormState(prevState => ({
                ...prevState,
                dateStart: newValue,
                dateEnd: dayjs(newValue).add(30, 'days'),
            }));
        } else {
            setFormState(prevState => ({
                ...prevState,
                dateEnd: newValue,
            }));
            setErrorsState(prevState => ({
                ...prevState,
                dateStart: '',
                dateEnd: '',
            }));
        }
    };

    // Effacer le contenu detous les champs
    const handleClearAllFields = () => {
        // Effacer le contenu de tous les champs
        setMessageType(messageToEdit?.title || '');
        setFormState(initialState);
        setErrorsState(initialErrorsState);
    };

    const handleCancel = () => {
        handleClearAllFields()
        navigate(`/dashboard/municipalites/${cityCode}/mu-messages/mu-messages-list`)
    }

    const handleReset = () => {
        // Réinitialiser tous les états
        handleClearAllFields();
    };

    // Gerer la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        // le type de texte est 'html', donc on modifie les messages en conséquence le cas des apostrophes
        const newFrMessage = formState.text_fr ? formState.text_fr.replace(/'/g, '’') : '';
        const newEnMessage = formState.text_en ? formState.text_en.replace(/'/g, '’') : '';

        const messageToSave = {
            ...formState,
            text_fr: newFrMessage,
            text_en: newEnMessage,
        };

        const checkErrors = { ...errorsState };

        // Validation des champs
        checkErrors.title = formState.title === '' ? 'Le champ titre est obligatoire.' : '';

        checkErrors.version = (!formState.publicVersion && !formState.privateVersion)
            ? 'Au moins l\'une des deux versions doit être sélectionnée.'
            : '';

        checkErrors.idService = (!formState.publicVersion && formState.privateVersion && !formState.idService)
            ? 'Un service doit être sélectionné pour la version privée.'
            : '';

        checkErrors.text = (formState.text_fr === '' && formState.text_en === '')
            ? "Aucun message n'a été saisi. Cela implique qu'il n'y aura pas de message à afficher."
            : '';


        // Mise à jour de `formState`avec les nouveaux textes
        setFormState(messageToSave);
        // Mise à jour des erreurs d'état
        setErrorsState(checkErrors);

        // Vérification de la validité du formulaire
        const isFormValid =
            (messageToSave.title !== '' && !checkErrors.title) &&
            ((messageToSave.publicVersion === true || messageToSave.privateVersion === true) && !checkErrors.version) &&
            !(!messageToSave.publicVersion && messageToSave.privateVersion && !messageToSave.idService) &&
            (messageToSave.dateStart !== '' && !checkErrors.dateStart) &&
            (messageToSave.dateEnd !== '' && !checkErrors.dateEnd) &&
            ((messageToSave.text_fr !== '' || messageToSave.text_en !== '') && !checkErrors.text);

        if (isFormValid) {
            // console.log({ messageToSave })

            // result = true
            if (action === 'Update') {
                // const result = true;
                const result = await updateMuMessage(messageToSave);
                if (result)
                    console.log('Soumission du Formulaire de mise à jour pop-up message');
            } else if (action === 'AddNew') {
                // const result = true;
                const result = await addMuMessage(messageToSave);
                if (result)
                    console.log('Soumission du Formulaire d\'ajout d\'un nouveau pop-up message');
            }
            setIsFormValidated(true);
            // permet d'afficher la boite de dialogue à la soumission du formulaire
            setOpen(true);
        } else {
            console.log('Un des champs est manquant ou mal saisi');
            setIsFormValidated(false);
            // permet d'afficher la boite de dialogue à la soumission du formulaire
            setOpen(true);
        }
    }


    return (
        <div className='PopUpMessages'>
            {open && (
                <DialogConfirm
                    formState={formState}
                    open={open}
                    setOpen={setOpen}
                    action={action}
                    isFormValidated={isFormValidated}
                    errorsState={errorsState}
                    handleClearAllFields={handleClearAllFields}
                />
            )}

            <Paper elevation={5} className='PopUpMessages-paper' sx={{ height: 600, overflowY: 'auto' }}>

                <form ref={PopUpMessagesFormRef} className='PopUpMessages-form' onSubmit={handleSubmit}>

                    <Grid container className='gridContainer' spacing={2} m='0' >

                        <Grid container className='gridContainer-first-block'>
                            {action === 'AddNew' &&
                                <Grid item className='gridItem' xs={12} md={12} lg={12} >
                                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                        <Stack spacing={1} >
                                            <Autocomplete
                                                {...messageTypeListDefaultProps}
                                                id="messageTypeList"
                                                name="messageTypeList"

                                                isOptionEqualToValue={(option, value) => option.idtypeMessage === value.idtypeMessage}

                                                value={messageType || null}
                                                onChange={(e, newValue) => {
                                                    // console.log('Type de message newValue ===> ', newValue)
                                                    handleClearAllFields()
                                                    if (newValue) {
                                                        setMessageType(newValue)
                                                        setFormState(prevState => ({
                                                            ...prevState,
                                                            idtypeMessage: newValue.idtypeMessage,
                                                        }));
                                                    }
                                                }}

                                                clearOnEscape
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Type du message"
                                                        variant="standard"
                                                        name="messageTypeList"
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>

                        {messageType &&
                            <Grid container className='gridContainer-second-block'>

                                <Grid item className='gridItem' xs={12} md={6} lg={6}>
                                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                        <InputLabel htmlFor="fullname">Auteur {action === 'Update' ? 'de la mise à jour' : ''} </InputLabel>
                                        <Input
                                            id="fullname"
                                            name="fullname"
                                            autoComplete="fullname"
                                            disabled
                                            value={currentUserInfo?.fullName || ''}
                                        // value={author || 'Information non précisée'}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item className='gridItem' xs={12} md={6} lg={6}  >
                                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
                                        <InputLabel htmlFor="titre">Titre du message</InputLabel>
                                        <Input
                                            id="titre"
                                            name="titre"
                                            autoComplete="titre"
                                            required

                                            value={formState.title || ''}

                                            onChange={(e) => {
                                                const newValue = capitalizeTheFirstLetter(e.target.value);

                                                // Mettre à jour formState avec la nouvelle valeur de title
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    title: newValue
                                                }));

                                                // Réinitialiser l'erreur du champ title
                                                setErrorsState(prevState => ({
                                                    ...prevState,
                                                    title: ''
                                                }));
                                            }}

                                            endAdornment={
                                                formState.title && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                setFormState(prevState => ({
                                                                    ...prevState,
                                                                    title: ''
                                                                }));
                                                                setErrorsState(prevState => ({
                                                                    ...prevState,
                                                                    title: ''
                                                                }));
                                                            }}
                                                            sx={{ cursor: 'pointer' }
                                                            }
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }
                                        />
                                        {errorsState.title && <Typography variant="caption" color="error">{errorsState.title}</Typography>}
                                    </FormControl>
                                </Grid>

                                {(messageType.idtypeMessage === 3 || messageType.idtypeMessage === 1) &&
                                    <>
                                        <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                                            <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                                                <DatePicker
                                                    label="Date début d'affichage"
                                                    format="YYYY/MM/DD"
                                                    openTo="day"
                                                    views={["year", "month", "day"]}
                                                    slotProps={{
                                                        textField: {
                                                            variant: "filled",
                                                            size: "small",
                                                            style: { width: '100%', height: '100%' }
                                                        }
                                                    }}

                                                    value={formState.dateStart || null}
                                                    onChange={(newValue) => {
                                                        handleStartDateChange(newValue)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            {errorsState.dateStart && <p style={{ color: 'red' }}>{errorsState.dateStart}</p>}
                                        </Grid>

                                        <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                                            <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                                                <DatePicker
                                                    label="Date fin d'affichage"
                                                    format="YYYY/MM/DD"
                                                    openTo="day"
                                                    views={["year", "month", "day"]}
                                                    slotProps={{
                                                        textField: {
                                                            variant: "filled",
                                                            size: "small",
                                                            style: { width: '100%', height: '100%' }
                                                        }
                                                    }}

                                                    value={formState.dateEnd || null}
                                                    onChange={(newValue) => {
                                                        handleEndDateChange(newValue)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            {errorsState.dateEnd && <p style={{ color: 'red' }}>{errorsState.dateEnd}</p>}
                                        </Grid>
                                    </>
                                }

                                <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>
                                    <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                        <FormControlLabel className='gridItem-formControlLabel left'
                                            control={
                                                <Checkbox
                                                    className='checkbox-version'
                                                    id="publicVersion"
                                                    name="publicVersion"

                                                    checked={formState.publicVersion || false}
                                                    onChange={(e) => {
                                                        setFormState(prevState => ({
                                                            ...prevState,
                                                            publicVersion: e.target.checked,
                                                            idService: null,
                                                        }));
                                                        setErrorsState((prevError) => ({
                                                            ...prevError,
                                                            version: '',
                                                            idService: '',
                                                        }))
                                                    }}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'publicVersion checkbox' }}
                                                />
                                            }
                                            label={
                                                <div className='version-label'>
                                                    Version publique
                                                </div>
                                            }
                                            labelPlacement="end"
                                        />
                                    </FormControl>

                                    <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                        <FormControlLabel className='gridItem-formControlLabel right'
                                            control={
                                                <Checkbox
                                                    className='checkbox-version'
                                                    id="privateVersion"
                                                    name="privateVersion"

                                                    checked={formState.privateVersion || false}
                                                    onChange={(e) => {
                                                        setFormState(prevState => ({
                                                            ...prevState,
                                                            privateVersion: e.target.checked,
                                                            idService: null,
                                                        }));
                                                        setErrorsState((prevError) => ({
                                                            ...prevError,
                                                            version: '',
                                                            idService: '',
                                                        }))
                                                    }}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'contact checkbox' }}
                                                />
                                            }
                                            label={
                                                <div className='version-label'>
                                                    {/* <PermContactCalendarIcon
                                                        sx={{
                                                            // color: contact ? 'green' : (municipality ? 'action.active' : 'lightgray'), // Use light gray if disabled

                                                            // color: contact ? 'green' : 'action.active',
                                                            marginRight: '0.5rem'
                                                        }}
                                                    /> */}
                                                    Version privée
                                                </div>
                                            }
                                            labelPlacement="end"
                                        />
                                    </FormControl>
                                </Grid>

                                {(!formState.publicVersion
                                    && formState.privateVersion
                                    && (messageType.idtypeMessage === 3 || messageType.idtypeMessage === 1)
                                ) &&
                                    <Grid item className='gridItem' xs={12} md={6} lg={6} >
                                        <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                            <Stack spacing={1} >
                                                <Autocomplete
                                                    {...servicesDefaultProps}
                                                    id="services"
                                                    name="services"

                                                    isOptionEqualToValue={(option, value) => option.idService === value.idService}
                                                    // Trouver l'objet complet ou retourner null si non trouvé
                                                    value={services.find(service => service.idService === formState.idService) || null}
                                                    onChange={(event, newValue) => {
                                                        // Si newValue n'est pas null avant d'essayer d'accéder à sa propriété
                                                        if (newValue) {
                                                            setFormState(prevState => ({
                                                                ...prevState,
                                                                idService: newValue.idService,
                                                            }));

                                                            setErrorsState((prevError) => ({
                                                                ...prevError,
                                                                idService: '',
                                                            }))
                                                        } else {
                                                            // Gérer le cas où aucune option n'est sélectionnée ou toute autre valeur par défaut appropriée
                                                            setFormState(prevState => ({
                                                                ...prevState,
                                                                idService: null,
                                                            }));

                                                            setErrorsState((prevError) => ({
                                                                ...prevError,
                                                                idService: '',
                                                            }))
                                                        }
                                                    }}

                                                    clearOnEscape
                                                    renderInput={(params) => <TextField {...params} label="Services" variant="standard" name="services" />}
                                                />
                                            </Stack>
                                        </FormControl>
                                    </Grid>
                                }


                                {messageType.idtypeMessage === 1 &&
                                    <>
                                        <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>
                                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                                <FormControlLabel
                                                    className='gridItem-formControlLabel left'
                                                    control={
                                                        <Checkbox
                                                            className='autoClose checkbox-version'
                                                            id="autoClose"
                                                            name="autoClose"

                                                            checked={formState.autoClose || true}

                                                            onChange={(e) => {
                                                                setFormState(prevState => ({
                                                                    ...prevState,
                                                                    autoClose: e.target.checked,
                                                                }));
                                                            }}

                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'autoClose checkbox' }}
                                                        />
                                                    }
                                                    label={
                                                        <div className='version-label'>
                                                            Auto-fermeture
                                                        </div>
                                                    }
                                                    labelPlacement="end"
                                                />
                                            </FormControl>

                                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                                <FormControlLabel
                                                    className='gridItem-formControlLabel right'
                                                    control={
                                                        <Checkbox
                                                            className='userClose checkbox-version'
                                                            id="userClose"
                                                            name="userClose"

                                                            checked={formState.userClose || false}

                                                            onChange={(e) => {
                                                                setFormState(prevState => ({
                                                                    ...prevState,
                                                                    userClose: e.target.checked,
                                                                }));
                                                            }}

                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'userClose checkbox' }}
                                                        />
                                                    }
                                                    label={
                                                        <div className='version-label'>
                                                            Fermeture par l'utilisateur
                                                        </div>
                                                    }
                                                    labelPlacement="end"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item className='gridItem' xs={12} md={6} lg={6} >
                                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                                <div className='sliderLabel'>
                                                    <Typography gutterBottom>Temps d'affichage &nbsp; </Typography>
                                                    <div className='sliderInput'>
                                                        <Input
                                                            size="small"
                                                            value={formState.displayTime / 1000 || 0}
                                                            onChange={(e) => {
                                                                setFormState(prevState => ({
                                                                    ...prevState,
                                                                    displayTime: (e.target.value === '' ? 0 : Number(e.target.value) * 1000),
                                                                }));
                                                            }}
                                                            inputProps={{
                                                                step: 1,
                                                                min: 0,
                                                                max: 60,
                                                                type: 'number',
                                                            }}
                                                        />
                                                        <Typography gutterBottom>
                                                            &nbsp; <span style={{ fontStyle: 'italic', fontSize: 'small' }}>sec</span>
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <Slider
                                                    aria-label="Temps"
                                                    defaultValue={formState.displayTime / 1000 || 0}
                                                    valueLabelDisplay="auto"
                                                    valuelabelplacement="bottom"

                                                    value={formState.displayTime / 1000 || 0}

                                                    onChange={(e) => {
                                                        setFormState(prevState => ({
                                                            ...prevState,
                                                            displayTime: (e.target.value === '' ? 0 : Number(e.target.value) * 1000),
                                                        }));
                                                    }}

                                                    step={1}
                                                    min={0}
                                                    max={60}
                                                    aria-labelledby="input-slider"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item className='gridItem' xs={12} md={6} lg={6}>
                                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
                                                <div className='orderBy'>
                                                    <Typography gutterBottom>Ordre d'affichage &nbsp; </Typography>
                                                    <Input
                                                        className='orderBy-control'
                                                        id="orderBy"
                                                        name="orderBy"
                                                        size="small"
                                                        value={formState.orderBy || 0}
                                                        onChange={(e) => {
                                                            setFormState(prevState => ({
                                                                ...prevState,
                                                                orderBy: (e.target.value === '' ? 1 : Number(e.target.value)),
                                                            }));
                                                        }}
                                                        inputProps={{
                                                            step: 1,
                                                            min: 0,
                                                            max: 100,
                                                            type: 'number',
                                                        }}
                                                    />
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }

                                <hr />

                                <Grid item className='gridError' xs={12} md={12} lg={12} >
                                    {errorsState.version && <Typography variant="caption" color="error">{errorsState.version}</Typography>}
                                </Grid>

                                <Grid item className='gridError' xs={12} md={12} lg={12} >
                                    {errorsState.idService && <Typography variant="caption" color="error">{errorsState.idService}</Typography>}
                                </Grid>

                                <Grid item className='gridError' xs={12} md={12} lg={12} >
                                    {errorsState.text && <Typography variant="caption" color="error">{errorsState.text}</Typography>}
                                </Grid>

                                <hr />

                                <EditorWysiwyg language={'français'} message={formState.text_fr} setMessage={setFormState} setMessageError={setErrorsState} />

                                <EditorWysiwyg language={'anglais'} message={formState.text_en} setMessage={setFormState} setMessageError={setErrorsState} />

                                <hr />

                                <div className='footerPopUpgMessages'>
                                    <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }} onClick={handleCancel}>
                                        Annuler
                                    </Button>

                                    <Button className='btnSubmit' type="reset" variant="contained" sx={{ width: '200px', cursor: 'pointer' }} onClick={handleReset}>
                                        Réinitialiser
                                    </Button>

                                    <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                                        Enregistrer
                                    </Button>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </form>
            </Paper>
        </div>
    );
}
