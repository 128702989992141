import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';
import { useAuth } from '../../contexts/AuthContext';


export default function DialogConfirm({ open, setOpen, message, hiddenEmail, response }) {

  const status = response.status;
  const { sendEmail } = useAuth();

  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false);
    if (openSecondDialog)
      navigate('/');
  };

  const handleConfirm = async () => {
    // let confirmationMessage = '';
    if (status === 'success' || status === 'double') {
      try {
        const emailResponse = await sendEmail({
          username: response.userName,
          email: response.userEmail,
          fullName: response.fullName
        });

        console.log(emailResponse)
        // confirmationMessage = 'Le courriel a été envoyé.';
        setOpenSecondDialog(true);


      } catch (error) {
        console.error("Erreur lors de l'envoi :", error);
      }
    }

    // setOpen(false)
    // setOpenDialog(false);

  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  return (
    openSecondDialog ? (
      // à verifier open={openDialog} ou open={openSecondDialog}
      <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
        <DialogTitle>Confirmation d'envoi de courriel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Un courriel a été envoyé à {hiddenEmail}. Vérifier votre boîte de réception et suivre les instructions pour changer votre mot de passe.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>

    ) : (

      <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>

        {(status === 'success' || status === 'double') ?
          (<DialogTitle>Confirmation d'envoi de courriel</DialogTitle>
          ) : (
            <DialogTitle>Vérification de données </DialogTitle>
          )}

        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>

        {(status === 'success' || status === 'double') ?
          (<DialogActions>
            <Button onClick={handleClose} color="error">Annuler</Button>
            <Button onClick={handleConfirm}>confirmer</Button>
          </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          )}

      </Dialog>
    )
  )
}

