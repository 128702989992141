import React, { useEffect, useState } from "react";
import "./MuListeMessages.scss";
import GridTable from "../data-grid/GridTable";
import SearchBar from "../../../../components/search-bar/SearchBar";
import { getPopUpMessagesList } from "../httpQuerys";


export default function MuListeMessages({ allowedManagement }) {

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [mustReload, setMustReload] = useState();

  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

  useEffect(() => {
    getPopUpMessagesList(currentCity.cityCode).then((data) => {
      setData(data);
      setMustReload(false)
    })
  }, [currentCity.cityCode, mustReload]);

  // search bar fonction
  const tabKeys = ["title", "fullName", "typedescription", "dateStart", "dateEnd"]

  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    data &&
    currentCity && (
      <div className="MuListeMessages">
        <SearchBar query={query} setQuery={setQuery} />
        <GridTable data={showList(data)} setData={setData} setMustReload={setMustReload} allowedManagement={allowedManagement} />
      </div>
    )
  );
}
