import dataService from "../../httpService";

// Fonction pour obtenir les municipalités de l'utilisateur
const getUserMunicipalitesData = async (gde, currentUserInfo) => {
    try {
        const response = await dataService.get('/user/municipalities', { params: { gde, currentUserInfo } });

        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir les municipalités de l'utilisateur
const groupMunicipalitiesGetList = async (gde, currentUserInfo) => {
    try {
        const response = await dataService.get('/group/municipalities', { params: { gde, currentUserInfo } });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les utilisateurs
async function getAllUsersData(currentUserInfo) {
    try {
        const response = await dataService.get('/user/all-users', { params: { currentUserInfo } });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir les données de l'utilisateur à éditer
async function getUserDetail(idUser) {
    try {
        const response = await dataService.get('/user/details', {
            params: {
                idUser
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les rôles possible de les sélectionner
async function getRoles() {
    try {
        const response = await dataService.get('/user/roles');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les titres possible de les sélectionner
async function getTitles() {
    try {
        const response = await dataService.get('/user/titles');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les départements possible de les sélectionner
async function getDepratements() {

    try {
        const response = await dataService.get('/user/departements');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste de tous les profils
async function getProfileList(idGroup) {
    // console.log(idGroup);
    try {
        const response = await dataService.get('/user/profile-list', {
            params: {
                idGroup
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste de tous les profils
async function getServicesProfile(cityCode, idGroup, nameProfile) {
    // console.log('===>>',cityCode, idGroup, nameProfile)
    try {
        const response = await dataService.get('/user/services-profile-list', {
            params: {
                cityCode, idGroup, nameProfile
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier l'unicité de l'identifiant userNAme de l'utilisateur à ajouter
async function checkUserNameUniqueness(userName) {
    try {
        const response = await dataService.get('/user/all-users/new-user/check-user-name', {
            params: { idUser: userName }
        });
        // console.log(response.data[0].duplicatedIdUser)
        return response.data[0].duplicatedIdUser;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier l'unicité de l'email de l'utilisateur à ajouter
async function checkUserEmailUniqueness(email) {
    try {
        const response = await dataService.post('/user/all-users/new-user/check-user-email', {
            email: email
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier le mot de passe de l'utilisateur principal
async function checkUserPassword(password) {
    try {
        const response = await dataService.get('/user/check-password', {
            params: {
                password
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour ajoter un nouvel utilisateur
async function addNewUserToDB(newUserToAdd) {
    try {
        const response = await dataService.post('/user/all-users/add-new-user', newUserToAdd)
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour ajouter un nouvel utilisateur à une municipalité
async function addNewMunicipalityUser(userToAdd) {
    try {
        const response = await dataService.post('/user/add-new-municipality-user', userToAdd);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour ajouter un nouvel utilisateur ainsi que les municipalités sélectionnée et leurs services
async function addUserMunicipalitiesAndServices(userToAdd) {
    // console.log({ userToAdd })
    try {
        const response = await dataService.post('/user/add-new-user-municipalities-services', userToAdd);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour supprimer un utilisateur à une municipalité
const deleteSelectedOneUserOffAll = async (idUser) => {
    try {
        const response = await dataService.delete('/user/delete-one-off-all-user', {
            params: { idUser }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des municipalités de l'utilisateur à éditer
async function getUserMunicipalitesList(idUser) {
    try {
        const response = await dataService.get('/user/all-users/user/to-edit/municipalites', {
            params: {
                idUser
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction de mise à jour d'un utilisateur, ses profils et ses services
async function updateOneUserOfAll(userToEdit) {
    try {
        const response = await dataService.put('/user/all-users/one-user/update', {
            updatedUser: userToEdit
        });
        return response;
    } catch (error) {
        throw error;
    }
}

// Fonction pour envoyer le mot de passe de l'utilisateur
async function sendEmailToNewUser(userToAdd) {
    try {
        const response = await dataService.post('/user/send-email-new-user', userToAdd);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Exporter les fonctions
export {
    getUserMunicipalitesData,
    groupMunicipalitiesGetList,
    getAllUsersData,
    getUserDetail,
    getRoles,
    getTitles,
    getDepratements,
    getProfileList,
    getServicesProfile,
    checkUserNameUniqueness,
    checkUserEmailUniqueness,
    checkUserPassword,
    addNewUserToDB,
    addNewMunicipalityUser,
    addUserMunicipalitiesAndServices,
    deleteSelectedOneUserOffAll,
    getUserMunicipalitesList,
    updateOneUserOfAll,
    sendEmailToNewUser
};