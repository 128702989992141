import DOMPurify from 'dompurify';
// import { checkUserEmailUniqueness } from './httpQuerys'

export const validateEmail = async (value, setFieldError) => {
    const sanitizedValue = DOMPurify.sanitize(value);

    if (!sanitizedValue.trim()) {
        setFieldError("L'adresse e-mail est obligatoire.");
        return false;
    }

    // Vérification de la syntaxe de l'adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(sanitizedValue)) {
        setFieldError("Veuillez fournir une adresse e-mail valide.");
        return false;
    }

    // const verifyUniqueEmail = async (sanitizedValue) => {
    //     return new Promise(async (resolve) => {
    //         try {
    //             // Vérification de l'unicité de l'adresse e-mail
    //             const isEmailUnique = await checkUserEmailUniqueness(sanitizedValue);
    //             // console.log('isEmailUnique ==> ', isEmailUnique[0].duplicatedEmail)
    //             if (isEmailUnique[0].duplicatedEmail > 0) {
    //                 setFieldError("Cette adresse e-mail est déjà utilisée.");
    //                 resolve(false);
    //             } else {
    //                 setFieldError('');
    //                 resolve(true);
    //             }
    //         } catch (error) {
    //             // Gestion de l'erreur de la requête
    //             // console.error(error);
    //             setFieldError("Une erreur s'est produite lors de la vérification de l'adresse e-mail.");
    //             resolve(false);
    //         }
    //     });
    // };

    // const isEmailUnique = await verifyUniqueEmail(sanitizedValue);
    // // console.log({ isEmailUnique })

    // // Validation réussie
    // return isEmailUnique;

    // Validation réussie
    setFieldError('');
    return true;
};

export const validatePassword = (password, setPasswordError, setPasswordStrength) => {
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    const passwordRegex = /^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/;
    const sanitizedPassword = DOMPurify.sanitize(password);
    const isPasswordValid = passwordRegex.test(sanitizedPassword);

    if (!sanitizedPassword.trim()) {
        setPasswordError("Mot de passe obligatoire !");
        setPasswordStrength('Faible');
        return false;
    }

    // Vérification de la présence de caractères spéciaux
    const specialCharRegex = /[^A-Za-z\d]/;
    if (specialCharRegex.test(sanitizedPassword)) {
        setPasswordError("Les caractères spéciaux ne sont pas acceptés !");
        setPasswordStrength('Faible');
        return false;
    }

    if (!isPasswordValid) {
        if (sanitizedPassword.length < 5) {
            setPasswordError("Mot de passe Faible !");
            setPasswordStrength('Faible');
            return false;
        }
        else {
            const passwordErrorMessage = "Mot de passe Moyen !";
            // Au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre, au moins un caractère spécial parmi @$!%*?&#, et une longueur minimale de 8 caractères.
            setPasswordError(passwordErrorMessage);
            setPasswordStrength('Moyen');
            return true;
        }
    }

    // Validation réussie
    setPasswordError('');
    setPasswordStrength('Élevé');
    return true;
};

export const validateField = async (value, fieldName, setFieldError) => {
    if (value.includes('<') || value.includes('>')) {
        setFieldError(`Le champ ${fieldName} doit contenir uniquement des lettres.`);
        return false;
    }

    const sanitizedValue = DOMPurify.sanitize(value);

    if (!sanitizedValue.trim()) {
        setFieldError(`Le champ ${fieldName} est obligatoire.`);
        return false;
    }

    if (!/^[a-zA-ZÀ-ÿ\s'-]+$/i.test(sanitizedValue)) {
        setFieldError(`Le champ ${fieldName} doit contenir uniquement des lettres.`);
        return false;
    }

    // Validation réussie
    setFieldError('');
    return true;
};

export const formatPhoneNumber = (value) => {
    if (!value) {
        return value;
    }

    const phoneNumber = value.replace(/[^\d]/g, '');

    if (phoneNumber.length < 4) return value;
    if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

// export const generateRandomPassword = () => {
//     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&';
//     let generatedPassword = '';

//     do {
//         generatedPassword = '';
//         for (let i = 0; i < 8; i++) {
//             const randomIndex = Math.floor(Math.random() * characters.length);
//             generatedPassword += characters[randomIndex];
//         }
//     } while (!passwordRegex.test(generatedPassword));

//     return generatedPassword;
// };

export const generateRandomPassword = () => {
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const passwordRegex = /^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/;

    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let generatedPassword = '';

    do {
        generatedPassword = Array.from({ length: 6 }, () =>
            characters.charAt(Math.floor(Math.random() * characters.length))
        ).join('');
    } while (!passwordRegex.test(generatedPassword));

    return generatedPassword;
};

const Utils = {
    validatePassword,
    validateField,
    formatPhoneNumber,
    generateRandomPassword,
}
export default Utils;