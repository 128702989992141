import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';
import { deleteMessage } from '../httpQuerys';

export default function DialogConfirm({ messageToAddOrEdit, open, setOpen, action, isFormValidated, titleError, versionError, textError, handleClearAllFields }) {

  const navigate = useNavigate();
  const { cityCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');

  const handleClose = () => {
    setOpen(false)
    navigate(`/dashboard/municipalites/${cityCode}/mu-configuration/warning-messages`);
    handleClearAllFields()
  };

  const handleCancel = () => {
    setOpen(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setOpen(false)
    }
  };

  // Fonction à implémenter pour la confirmation de suppression
  const handleConfirm = async () => {
    console.log('Deleting selected rows ');
    try {

      const response = await deleteMessage(messageToAddOrEdit);

      if (response) {
        console.log('Suppression effectuée avec succés.');
        setMessageConfirmationDialog('Suppression effectuée avec succés.')
        navigate(`/dashboard/municipalites/${cityCode}/mu-configuration/warning-messages`);
        handleClearAllFields();
        setOpenSecondDialog(true);

      } else {
        console.log('Erreur lors de la Suppression !');
        setMessageConfirmationDialog('Erreur lors de la Suppression !')
        // Afficher la boîte de dialogue de confirmation
        setOpenSecondDialog(true);
      }

    } catch (error) {
      console.error('Error deleting selected rows', error);
    }
  }

  return (
    action === 'Delete' ? (
      openSecondDialog ? (
        <div>
          <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
            <DialogTitle>Confirmation de la suppression du message</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <strong><em>{messageConfirmationDialog}</em></strong>
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
            <DialogTitle>Confirmation de suppression d'utilisateur(s)</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Êtes-vous sûr de vouloir retirer ce message d'avertissement ?<br />
                Cette action est irréversible.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} color="error">Annuler</Button>
              <Button onClick={handleConfirm}>Confirmer</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    ) : (
      isFormValidated ? (
        <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
          <DialogTitle>Confirmation {action === 'Update' ? ' de mise à jour ' : ' d\'ajout '} du message d'avertissement</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Le message d'avertissement a été {action === 'AddNew' ? 'ajouté' : action === 'Update' ? 'modifié' : ''} correctement.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleCancel} onKeyDown={handleKeyDown}>
          <DialogTitle>Erreur dans le formulaire</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Un ou plusieurs champs du formulaire sont manquants ou érronés.<br />
              Veuillez vérifier vos données ! <br />
              {titleError && <span> - {titleError}<br /></span>}
              {versionError && <span> - {versionError}<br /></span>}
              {textError && <span> - {textError}<br /></span>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="error">Retour au Formulaire</Button>
          </DialogActions>
        </Dialog>
      )
    )
  );
}