import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import {
  addNewMiseEnLigne
} from './httpQuerys'
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

export default function DialogConfirm({ formDataMEL, open, setOpen, isFormValidated }) {

  const navigate = useNavigate();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)

    navigate(`/dashboard/municipalites/${formDataMEL.cityCode}/mu-mise-en-ligne/list`);
  };

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  // Vérifie si la touche pressée est la touche "Échap" (code 27) pour fermer la fenêtre
  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      handleClose(); // Appelle la fonction handleClose
    }
  };


  // Fonction pour la confirmation de l'ajout d'une nouvelle mise en ligne
  const handleConfirm = async () => {
    console.log('Add new mise à jour in DB ');

    try {
      // Mettre à jour les données dans une nouvelle variable
      const updatedFormDataMEL = {
        ...formDataMEL, // Copier l'état actuel
        dateMaj: toDay  // Mettre à jour la date
      };

      const response = await addNewMiseEnLigne(updatedFormDataMEL);

      if (response) {
        setMessageConfirmationDialog('La requête de mise à jour a été ajoutée avec succès.');
        setOpenSecondDialog(true); // Ouvrir la boîte de confirmation après succès
      } else {
        setMessageConfirmationDialog('Erreur lors de l\'ajout de la requête de mise à jour.');
        setOpenSecondDialog(true); // Ouvrir la boîte en cas d'erreur
      }
    }
    catch (error) {
      console.error('Erreur lors de l\'ajout de la requête de mise à jour.', error);
      setMessageConfirmationDialog('Erreur lors de l\'ajout de la requête de mise à jour.');
      setOpenSecondDialog(true);
    }
  }
  return (
    openSecondDialog ? (

      <Dialog open={openSecondDialog} onClose={handleClose} onKeyDown={handleEscape}>
        <DialogTitle>Confirmation d'ajout de nouvelle mise en ligne</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Une requête d'ajout d'une nouvelle mise en ligne de type {formDataMEL.typeMaj} est lancée.<br /><br />
            Un temps de traitement de votre demande est requis, vous serais aviser de toute mise à jour en temps et en heure ! <br /><br />
            {messageConfirmationDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Fermer</Button>
        </DialogActions>
      </Dialog>

    ) : (
      isFormValidated ? (
        <div>
          <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
            <DialogTitle>Requête d'ajout d'une nouvelle mise en ligne</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Vous êtes sur le point de soumettre une requête de mise en ligne de type <strong>{formDataMEL.typeMaj}</strong> pour la ville de <em>{currentCity.cityName}</em>.
                <br /><br />
                Veuillez confirmer cette requête !
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleCancel} variant="contained" color="error">Annuler</Button>
              <Button onClick={handleConfirm} variant="contained" color="primary">Confirmer</Button> */}
              <Button onClick={handleCancel} color="error">Annuler</Button>
              <Button onClick={handleConfirm} color="primary">Confirmer</Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Erreur dans le formulaire</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Un ou plusieurs champs du formulaire sont incorrects ou manquants! <br /><br />                Veuillez vérifier vos données.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleCancel} variant="contained" color="error">Retour au Formulaire</Button> */}
              <Button onClick={handleCancel} color="error">Retour au Formulaire</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    )
  )
}
