import React from 'react'
import { useParams } from 'react-router';
import MuHeader from '../../../../components/mu-header/MuHeader'
import EditUserForm from './EditUserForm';
import './EditUser.scss'

export default function EditUser() {

    const { idUser, idGroup } = useParams();

    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));

    return (

        <div className='muEditUser-main-wrapper'>
            <MuHeader currentCity={currentCity}/>
            <EditUserForm idUser={ idUser } idGroup={idGroup} currentCity={currentCity}/>
        </div>
    )
}
